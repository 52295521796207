import React from 'react';
import { generateThumborImgUrl } from 'src/shared/design-system/DynamicImage';
import { cssPixel } from 'src/shared/utils/StyleUnitsUtils';
import styled, { css } from 'styled-components';
import { Colors, ScreenWidth } from 'web-common/src/shared/styles';

const SHEF_IMAGE_WIDTH = 145;
const SHEF_IMAGE_SMALL_SCREEN_WIDTH = 120;
export const SHEF_IMAGE_SMALL_SCREEN_BORDER_WIDTH = 4;

const ShefImg = styled.img<{ width: number; smallScreenWidth?: number }>`
  ${({ width }) => {
    const widthPixel = cssPixel(width);

    return css`
      height: ${widthPixel};
      width: ${widthPixel};
      min-width: ${widthPixel};
    `;
  }}

  border-radius: 50%;
  object-fit: cover;
  background: ${Colors.GRAY_LITE_30};

  @media (max-width: ${ScreenWidth.SMALL}) {
    ${({ smallScreenWidth, width }) => {
      const widthPixel = cssPixel(smallScreenWidth ?? width);

      return css`
        height: ${widthPixel};
        width: ${widthPixel};
        min-width: ${widthPixel};
      `;
    }}
    border: ${cssPixel(SHEF_IMAGE_SMALL_SCREEN_BORDER_WIDTH)} solid white;
  }
`;

interface ShefImageProps extends React.ComponentPropsWithoutRef<'img'> {
  width?: number;
  smallScreenWidth?: number;
}

export const ShefImage: React.FC<ShefImageProps> = ({
  width = SHEF_IMAGE_WIDTH,
  smallScreenWidth = SHEF_IMAGE_SMALL_SCREEN_WIDTH,
  src,
  alt = 'Shef',
  ...rest
}) => (
  <ShefImg
    width={width}
    smallScreenWidth={smallScreenWidth}
    src={src && generateThumborImgUrl({ src, width })}
    alt={alt}
    {...rest}
  />
);
