import { isMealPlanMainDish, isMealPlanSideDish } from 'common/MealPlanUtils';
import { Draft } from 'immer';
import { MealPlanFoodItem, MealPlanShefSegment } from 'src/pages/consumer/meal-plans/checkout/types';
import { create, StoreApi } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createSelectors } from './create-selectors';

export type MealPlanCartItem = {
  foodItem: MealPlanFoodItem;
  quantity: number;
};

export type CartForShef = {
  cartItems: MealPlanCartItem[];
  shefSegment: MealPlanShefSegment;
};

export type Cart = {
  [shefId: string]: CartForShef;
};

// Define the interface of the Cart state
interface State {
  preEditCart: Cart;
  cart: Cart;
  lastAddedMain: MealPlanFoodItem | null;
}

interface UpsellPriceBreakdownSection {
  totalCost: number;
  itemCount: number;
}
interface UpsellPriceBreakdown {
  mains: UpsellPriceBreakdownSection;
  sides: UpsellPriceBreakdownSection;
}
// Define the interface of the actions that can be performed in the Cart
interface Actions {
  increaseCartQuantity: (item: MealPlanCartItem, shefSegment: MealPlanShefSegment) => void;
  decreaseCartQuantity: (item: MealPlanCartItem) => void;
  clearLastAddedMain: () => void;
  clearCart: () => void;
  clearPreEditCart: () => void;
  setItemQuantity: (item: MealPlanCartItem, shefSegment: MealPlanShefSegment) => void;
  setPreEditCart: (preEditCart: Cart) => void;
  setCart: (cart: Cart) => void;
  /** TODO: Remove these after getting rid of sides */
  getCartForMainShefs: () => CartForShef[];
  getCartForSideShefs: () => CartForShef[];
  getNumItemsInCart: () => number;
  getNumSelectedMains: () => number;
  getNumSelectedSides: () => number;
  getUpsellsTotalExtraAmountFromCart: (numServings: number) => number;
  getUpsellPriceBreakdown: (numServings: number) => UpsellPriceBreakdown;
}

// Initialize a default state
export const INITIAL_STATE: State = {
  preEditCart: {},
  cart: {},
  lastAddedMain: null,
};

const getCartsForShefsByFoodItemIds = (cart: Cart, filterFn: (foodItemId: number) => boolean) =>
  Object.values(cart)
    .map((cartForShef) => ({
      ...cartForShef,
      cartItems: cartForShef.cartItems.filter((mpCartItem) => filterFn(mpCartItem.foodItem.id)),
    }))
    .filter((cartForShef) => cartForShef.cartItems.length > 0);

const setLastAddedMain = (set: (fn: (draft: Draft<State>) => void) => void, foodItem: MealPlanFoodItem) => {
  const isMainDish = isMealPlanMainDish(foodItem.id);
  set((state) => {
    state.lastAddedMain = isMainDish ? foodItem : null;
  });
};

const updateCartState = (
  set: (fn: (draft: Draft<State>) => void) => void,
  get: StoreApi<State & Actions>['getState'],
  shefId: string,
  cartForShef: { cartItems: MealPlanCartItem[]; shefSegment: MealPlanShefSegment },
  updatedCartItems: MealPlanCartItem[]
) => {
  if (updatedCartItems.length > 0) {
    set((state) => {
      state.cart[shefId] = { ...cartForShef, cartItems: updatedCartItems };
    });
  } else {
    set((state) => {
      delete state.cart[shefId];
    });
  }
};

export const useCartStore = create(
  persist(
    immer<State & Actions>((set, get) => ({
      ...INITIAL_STATE,
      increaseCartQuantity: (cartItem: MealPlanCartItem, shefSegment: MealPlanShefSegment) => {
        const { cart } = get();
        const shefId = shefSegment.shef.id;
        const cartForShef = cart[shefId] || { cartItems: [], shefSegment };

        const existingItemIndex = cartForShef.cartItems.findIndex((item) => item.foodItem.id === cartItem.foodItem.id);

        if (existingItemIndex === -1 && isMealPlanMainDish(cartItem.foodItem.id)) {
          setLastAddedMain(set, cartItem.foodItem);
        }

        const updatedCartItems =
          existingItemIndex !== -1
            ? cartForShef.cartItems.map((item, index) =>
                index === existingItemIndex ? { ...item, quantity: item.quantity + cartItem.quantity } : item
              )
            : [...cartForShef.cartItems, cartItem];

        updateCartState(set, get, shefId, cartForShef, updatedCartItems);
      },
      decreaseCartQuantity: (product: MealPlanCartItem) => {
        const { cart } = get();
        const shefId = product.foodItem.shef.id;
        const cartForShef = cart[shefId];

        if (!cartForShef) return;

        const updatedCartItems = cartForShef.cartItems
          .map((item) =>
            item.foodItem.id === product.foodItem.id ? { ...item, quantity: item.quantity - product.quantity } : item
          )
          .filter((item) => item.quantity > 0);

        updateCartState(set, get, shefId, cartForShef, updatedCartItems);
      },
      clearCart: () => {
        set({ cart: INITIAL_STATE.cart });
      },
      clearLastAddedMain: () => {
        set({ lastAddedMain: null });
      },
      clearPreEditCart: () => {
        set({ preEditCart: INITIAL_STATE.preEditCart });
      },
      setItemQuantity: (cartItem: MealPlanCartItem, shefSegment: MealPlanShefSegment) => {
        const { cart } = get();
        const shefId = shefSegment.shef.id;
        const cartForShef = cart[shefId] || { cartItems: [], shefSegment };

        const itemExists = cartForShef.cartItems.some((item) => item.foodItem.id === cartItem.foodItem.id);

        if (!itemExists && isMealPlanMainDish(cartItem.foodItem.id)) {
          setLastAddedMain(set, cartItem.foodItem);
        }

        const updatedCartItems = itemExists
          ? cartForShef.cartItems
              .map((item) =>
                item.foodItem.id === cartItem.foodItem.id ? { ...item, quantity: cartItem.quantity } : item
              )
              .filter((item) => item.quantity > 0)
          : [...cartForShef.cartItems, cartItem];

        updateCartState(set, get, shefId, cartForShef, updatedCartItems);
      },
      setPreEditCart: (preEditCart: Cart) => {
        const mergeCart = Object.entries(preEditCart).reduce((acc, [shefId, cartForShef]) => {
          const filteredCartItems = cartForShef.cartItems.filter((item) => item.quantity > 0);
          if (filteredCartItems.length > 0) {
            return { ...acc, [shefId]: { ...cartForShef, cartItems: filteredCartItems } };
          }
          return acc;
        }, {});
        set({ preEditCart: mergeCart });
        set({ cart: mergeCart });
      },
      setCart: (cart: Cart) => {
        set({ cart });
      },
      getCartForMainShefs: () => {
        const { cart } = get();
        return getCartsForShefsByFoodItemIds(cart, isMealPlanMainDish);
      },
      getCartForSideShefs: () => {
        const { cart } = get();
        return getCartsForShefsByFoodItemIds(cart, isMealPlanSideDish);
      },
      getNumItemsInCart: () => {
        const { cart } = get();
        return Object.values(cart).reduce(
          (acc, shef) => acc + shef.cartItems.reduce((itemAcc, item) => itemAcc + item.quantity, 0),
          0
        );
      },
      getNumSelectedMains: () => {
        const { cart } = get();
        const shefsWithMains = getCartsForShefsByFoodItemIds(cart, isMealPlanMainDish);
        return shefsWithMains.reduce(
          (acc, shef) => acc + shef.cartItems.reduce((itemAcc, item) => itemAcc + item.quantity, 0),
          0
        );
      },
      getNumSelectedSides: () => {
        const { cart } = get();
        const shefsWithSides = getCartsForShefsByFoodItemIds(cart, isMealPlanSideDish);
        return shefsWithSides.reduce(
          (acc, shef) => acc + shef.cartItems.reduce((itemAcc, item) => itemAcc + item.quantity, 0),
          0
        );
      },
      getUpsellsTotalExtraAmountFromCart: (numServings: number) => {
        const { cart } = get();
        const cartForMains = getCartsForShefsByFoodItemIds(cart, isMealPlanMainDish);
        return Object.values(cartForMains).reduce(
          (acc, shef) =>
            acc +
            shef.cartItems.reduce(
              (itemAcc, item) => itemAcc + (item.foodItem.upsell?.price ?? 0) * item.quantity * numServings,
              0
            ),
          0
        );
      },
      getUpsellPriceBreakdown: (numServings: number) => {
        const { cart } = get();
        const cartForMains = getCartsForShefsByFoodItemIds(cart, isMealPlanMainDish);
        return Object.values(cartForMains).reduce((acc, shef) => {
          shef.cartItems.forEach((item) => {
            const section = 'mains';
            if (!acc[section]) {
              acc[section] = {
                totalCost: 0,
                itemCount: 0,
              };
            }

            const upsellTotalCost = (item.foodItem.upsell?.price ?? 0) * item.quantity * numServings;

            acc[section].totalCost += upsellTotalCost;
            if (upsellTotalCost > 0) {
              acc[section].itemCount += item.quantity;
            }
          });

          return acc;
        }, {} as UpsellPriceBreakdown);
      },
    })),
    {
      name: 'cart-storage', // unique name
      getStorage: () => localStorage,
    }
  )
);

export const useCartStoreSelectors = createSelectors(useCartStore);
