import { ColorTheme } from '../Constants';
import { ExceptFields, JSONObj, JSONType } from '../TypeUtilities';
import { AppLocationSource, AuthMethod, FollowButtonClickLocation, NavClickElementName } from './ClientTypes';
import { TrackableSharedEvents } from './SharedEvents';

export enum ReferralShareLocation {
  ACCOUNT_SHARE = 'account-share',
  POST_ORDER = 'post-order',
  ACCOUNT_SHARE_SHEF = 'account-share-shef',
  ACCOUNT_SHARE_FOOD_ITEM = 'account-share-food-item',
  ACCOUNT_SHARE_ORDER = 'account-share-order',
  AFTER_5_STAR_REVIEW = 'after-5-star-review',
  BECOME_A_SHEF = 'become-a-shef',
  WAITLIST = 'waitlist',
  UPCOMING_MEAL_PLAN_ORDERS = 'upcoming-meal-plan-orders',
  MANAGE_MEAL_PLAN = 'manage-meal-plan',
}

export enum ShareType {
  WHATSAPP = 'Whatsapp',
  TWITTER = 'Twitter',
  FACEBOOK = 'Facebook',
  EMAIL = 'Email',
  TEXT = 'Text',
  COPY = 'copy',
  NATIVE = 'native', // Keep 'native' lowercase due to legacy data.
}

export enum BrowseByTagsType {
  COLLECTION = 'collection',
  DIETARY_PREFERENCE = 'dietary-preference',
  CUISINE = 'cuisine',
}

export enum ShefBioPlacements {
  SHEF_PIC = 'shef_pic',
  SHEF_NAME = 'shef_name',
}

export enum LoginModalDismissElements {
  CLOSE_BUTTON = 'close',
  BROWSE_AS_GUEST = 'browse-as-guest',
}

export type CartChangeLocation = 'mains-tab' | 'dish-modal' | 'sides-tab' | 'sides-modal' | 'cart';

export enum ClientEvents {
  PURCHASE = 'purchase',
  EXISTING_USER_PURCHASE = 'existing-user-purchase',
  NEW_USER_PURCHASE = 'new-user-purchase',
  CART_ADD_ITEM = 'cart-add-item',
  CART_REMOVE_ITEM = 'cart-remove-item',
  FREE_DELIVERY_ADD_ITEM = 'free-delivery-add-item',
  PURCHASE_ATTEMPT = 'purchase-attempt',
  PURCHASE_ATTEMPT_FAILED = 'purchase-attempt-failed',
  SUBSCRIPTION_PURCHASE_ATTEMPT = 'subscription-purchase-attempt',
  SUBSCRIPTION_PURCHASE = 'subscription-purchase',

  // Meal Planning MVP
  MEAL_PLANNING_MVP_FOOD_ITEM_RENDERED = 'meal-planning-mvp-food-item-rendered',
  MEAL_PLANNING_MVP_MY_CUISINE_RESULTS = 'meal-planning-mvp-my-cuisine-results',
  MEAL_PLANNING_MVP_ADD_TO_CART = 'meal_planning_mvp_add_to_cart',
  MEAL_PLANNING_MVP_REMOVE_FROM_CART = 'meal_planning_mvp_remove_from_cart',
  MEAL_PLANNING_MVP_SIDE_OR_STAPLE_ADD = 'meal_planning_mvp_side_or_staple_add',
  MEAL_PLANNING_MVP_CHECKOUT_STARTED = 'meal-planning-mvp-checkout-started',
  MEAL_PLANNING_MVP_DELIVERY_ADDRESS_SELECT = 'meal-planning-mvp-checkout-delivery-address-select',
  MEAL_PLANNING_MVP_DELIVERY_TIME_SELECT = 'meal-planning-mvp-checkout-delivery-time-select',
  MEAL_PLANNING_MVP_CREDIT_CARD_INPUT = 'meal-planning-mvp-checkout-credit-card-input',
  MEAL_PLANNING_MVP_PURCHASE = 'meal-planning-mvp-purchase',
  MEAL_PLANNING_MVP_PURCHASE_ATTEMPT = 'meal-planning-mvp-purchase-attempt',
  MEAL_PLANNING_MVP_MORE_SHEFS_CLICK = 'meal-planning-mvp-more-shefs-click',
  MEAL_PLANNING_MVP_DISH_UPDATE_ATTEMPT = 'meal-planning-mvp-dish-update-attempt',
  MEAL_PLANNING_MVP_SKIP_DELIVERY_ATTEMPT = 'meal-planning-mvp-skip-delivery-attempt',
  MEAL_PLANNING_SERVING_SIZE_MODAL_SHOWN = 'meal-planning-serving-size-modal-shown',
  MEAL_PLANNING_SERVING_SIZE_INFO_MODAL_OPEN = 'meal-planning-serving-size-info-modal-open',
  MEAL_PLANNING_SPLASH_PAGE_LOADED = 'meal-planning-splash-page-loaded',
  MEAL_PLANNING_UPCOMING_ORDER_RENDERED = 'meal-planning-upcoming-order-rendered',
  MEAL_PLANNING_UNSKIP_DELIVERY = 'meal-planning-unskip-delivery',
  MEAL_PLANNING_UNSKIP_DELIVERY_ERROR = 'meal-planning-unskip-delivery-error',
  MEAL_PLANNING_UPCOMING_ORDER_PAGE_VIEW = 'meal-planning-upcoming-order-page-loaded',
  MEAL_PLANNING_UPCOMING_ORDER_BTN_CLICK = 'meal-planning-upcoming-order-btn-click',
  MEAL_PLANNING_EDIT_DELIVERY_DATE_BTN_CLICK = 'meal-planning-edit-delivery-date-btn-click',
  MEAL_PLANNING_MANAGE_MEAL_PLAN_BTN_CLICK = 'meal-planning-manage-meal-plan-btn-click',
  MEAL_PLANNING_MVP_IMPERFECT_MATCH_RESULTS = 'meal-planning-mvp-imperfect-match-results',
  MEAL_PLANNING_PREFERENCES_SUBMITTED = 'meal-planning-preferences-submitted',
  MEAL_PLANNING_EDIT_PREFERENCE_STARTED = 'meal-planning-edit-preference-started',
  MEAL_PLANNING_EDIT_PREFERENCE_SAVED = 'meal-planning-edit-preference-saved',
  MEAL_PLAN_SHEF_BIO_CLICK = 'meal-plan-shef-bio-click',
  MANAGE_SUBSCRIPTION_DROPDOWN = 'manage-subscription-dropdown',
  SUBSCRIPTION_CANCEL_MODAL_CONFIRMATION = 'subscription-cancel-modal-confirmation',
  MEAL_PLAN_OPEN_CART = 'meal_plan_open_cart',
  EXPLORE_PERSONAL_MEAL_PLAN_BANNER_SHOWN = 'explore-personal-meal-plan-banner-shown',
  EXPLORE_PERSONAL_MEAL_PLAN_BANNER_CLICKED = 'explore-personal-meal_plan-banner-clicked',
  EXPLORE_MEAL_PLAN_MODAL_SHOWN = 'explore-meal-plan-modal-shown',
  EXPLORE_MEAL_PLAN_MODAL_CLAIM_OFFER = 'explore-meal-plan-modal-claim-offer',
  EXPLORE_MEAL_PLAN_MODAL_CLOSED = 'explore-meal-plan-modal-closed',

  // Flexible Sides Events
  MEAL_PLAN_SIDE_MODAL_SHOWN = 'meal-plan-side-modal-shown',
  MEAL_PLAN_SIDE_MODAL_SHEF_RENDERED = 'meal-plan-side-modal-shef-rendered',
  MEAL_PLAN_REVIEW_ORDER_SHOWN = 'meal-plan-review-order-shown',
  MEAL_PLAN_REVIEW_ORDER_CLOSED = 'meal-plan-review-order-closed',
  MEAL_PLAN_DISH_TYPE_NAVIGATION = 'meal-plan-dish-type-navigation',

  // Complete Meal Events
  INTAKE_HOUSEHOLD = 'intake-household',
  MEAL_PLAN_ANYTHING_ELSE_TO_KNOW = 'meal-plan-anything-else-to-know',
  INTAKE_PORTION_SIZE_SELECTED = 'intake-portion-size-selected',
  MEAL_PLAN_FILTERS_APPLIED = 'meal-plan-filters-applied',
  MEAL_PLAN_EDIT_TASTE_PROFILE_CLICKED = 'meal-plan-edit-taste-profile-clicked',
  MEAL_PLAN_TASTE_PROFILE_EDITED = 'meal-plan-taste-profile-edited',
  MEAL_PLAN_CART_ITEM_NOT_RETURNED = 'meal-plan-cart-item-not-returned',
  MEAL_PLAN_TASTE_PROFILE_INTAKE = 'meal-plan-taste-profile-intake',
  HOMEPAGE_MEAL_PLAN_CLICK = 'homepage-meal-plan-click',
  HOMEPAGE_MARKETPLACE_CLICK = 'homepage-marketplace-click',

  // TODO Trevomatic Remove after we figure out the zipCode / purchase failure issue
  MEAL_PLAN_NOT_AVAILABLE_ERROR = 'meal-plan-not-available-error',

  MEAL_PLANNING_MVP_INTAKE_FORM_STEP = 'meal-planning-mvp-intake-form-step',

  EXPLORE_MEAL_PLAN_HERO_BANNER_CLICK = 'explore-meal-plan-hero-banner-click',
  EXPLORE_MEAL_PLAN_HERO_BANNER_SHOWN = 'explore-meal-plan-hero-banner-shown',
  EXPLORE_MEAL_PLAN_HERO_BANNER_EXISTING_SUB_CLICK = 'explore-meal-plan-hero-banner-existing-sub-click',
  EXPLORE_MEAL_PLAN_HERO_BANNER_EXISTING_SUB_SHOWN = 'explore-meal-plan-hero-banner-existing-sub-shown',

  ORGANIZATION_LANDING = 'organization-landing',
  SHEF_APPLICATION_RECEIVED = 'shef-application-received',

  SUBSCRIPTION_MEAL_DETAILS_CLICK = 'subscription-meal-details-click',

  SHARED_NONE = 'share-none', // Should not ever be shared
  SHARE_SHEF_MENU = 'share-shef-menu',
  SHARE_SHEF_FOOD_ITEM = 'share-shef-food-item',
  SHARE_SHEF_PROMO_CODE = 'shate-shef-promo-code',
  REFERRAL_CODE_COPIED = 'referral-code-copied',
  REFERRAL_CODE_SHARED = 'referral-code-shared',
  REFERRAL_CODE_SHARED_FACEBOOK = 'referral-code-shared-facebook',
  REFERRAL_CODE_SHARED_TWITTER = 'referral-code-shared-twitter',
  REFERRAL_CODE_SHARED_WHATSAPP = 'referral-code-shared-whatsapp',
  REFERRAL_CODE_SHARED_EMAIL = 'referral-code-shared-email',
  REFER_COMPOSE_EMAIL = 'refer-compose-email',

  FOOD_SEARCH = 'food-search',
  FOOD_SEARCH_RESULTS_DISPLAYED = 'food-search-results-displayed',
  SHEF_SEARCH_RESULT_CLICK = 'shef-search-result-click',
  FOOD_SEARCH_RESULT_CLICK = 'food-search-result-click',
  CUISINE_SEARCH_RESULT_CLICK = 'cuisine-search-result-click',
  SEARCH_SHOW_MORE_SHEFS_CLICK = 'search-show-more-shefs-click',
  SEARCH_SHOW_MORE_DISHES_CLICK = 'search-show-more-dishes-click',
  MORE_CUISINES_CLICK = 'more-cuisines-click',
  GIFT_CARD_SEARCH_RESULT_CLICK = 'gift-card-search-result-click',
  TRENDING_SEARCH_CLICK = 'trending-search',

  API_SYNC_RELOAD = 'api-sync-reload',
  SESSION_STARTED = 'session-started',

  BECOME_A_SHEF_VISIT = 'become-a-shef-visit',

  PAGE_VIEW = 'page-view',

  SUBMIT_DELIVERY_DAY_FILTER = 'submit-delivery-day-filter',
  CLICK_DIETARY_FILTER = 'click-dietary-filter',
  SUBMIT_DIETARY_FILTER = 'submit-dietary-filter',
  CLICK_SPICE_FILTER = 'click-spice-filter',
  SUBMIT_SPICE_FILTER = 'submit-spice-filter',
  SUBMIT_CUISINE_CATEGORY_FILTER = 'submit-cuisine-category-filter',
  SELECT_CUISINE_FILTER = 'select-cuisine-filter',
  CLICK_PICKUP_DISTANCE_FILTER = 'click-pickup-distance-filter',
  // SUBMIT_PICKUP_DISTANCE_FILTER = 'submit-pickup-distance-filter', // deprecated 11-29-2022
  CAROUSEL_SWIPE = 'carousel-swipe',
  LANDING_ZIP_SUBMIT = 'landing-zip-submit',
  EXPLORE_ZIP_SUBMIT = 'explore-zip-submit',
  VIDEO_BIO_CLICK = 'video-bio-click',
  LEARN_MORE_CLICK = 'learn-more-click',
  CART_INCREMENT = 'cart-increment',
  CART_DECREMENT = 'cart-decrement',
  EXPLORE_SEGMENT_C2C_REFER_CLICK = 'explore-segment-c2c-refer-click',
  SHEF_SPOTLIGHT_CLICK = 'shef-spotlight-click',
  RECENTLY_REVIEWED_CLICK = 'recently-reviewed-click',

  // 4/8/22 Deprecated. Remove after no events have been logged for a while.
  NAV_HAMBUGER_MENU_CLICK = 'nav-hamburger-menu-click',

  NAV_CLICK = 'nav-click',

  AUTO_APPLIED_REWARD_PROMO = 'auto-applied-reward-promo',

  SHEF_MENU_VISIT = 'shef-menu-visit',
  DISH_MODAL_VISIT = 'dish-modal-visit',
  SHEF_PROFILE_IMAGE_CLICK = 'shef-profile-image-click',
  DISH_IMAGE_CLICK = 'dish-image-click',
  PREFAB_FOOD_ITEM_COPY = 'prefab-food-item-copy',
  FOOD_ITEM_SUGGESTIONS_VIEW = 'food-item-suggestions-view',

  AUTO_APPLIED_PROMO = 'auto-applied-promo',
  AUTO_APPLIED_SUBSCRIPTION_PROMO = 'auto-applied-subscription-promo',

  BECOME_A_SHEF_CLICK_GET_STARTED = 'become-a-shef-click-get-started',
  BECOME_A_SHEF_ENTER_INFO = 'become-a-shef-enter-info',
  BECOME_A_SHEF_CLICK_SIGN_UP = 'become-a-shef-click-sign-up',

  LOGIN_ATTEMPT = 'login-attempt',
  LOGIN_FAILED = 'login-failed',
  SIGN_UP_ATTEMPT = 'sign-up-attempt',
  SIGN_UP_ERROR = 'sign-up-error',
  SSO_AUTH_SUCCESS = 'sso-auth-success',
  SSO_AUTH_FAILED = 'sso-auth-failed',
  REGISTER_SUCCESS = 'register-success',
  LOGIN_MODAL_OPENED = 'login-modal-opened',
  LOGIN_MODAL_DISMISSED = 'login-modal-dismissed',
  LOGIN_MODAL_EMAIL_ENTERED = 'login-modal-email-entered',
  SSO_SIGNUP_CLICKED = 'sso-signup-clicked',

  CHECKOUT_DELIVERY_ADDRESS_SELECT = 'checkout-delivery-address-select',
  CHECKOUT_DELIVERY_TIME_SELECT = 'checkout-delivery-time-select',
  CHECKOUT_CREDIT_CARD_INPUT = 'checkout-credit-card-input',
  CHECKOUT_TIP_VALUE_UPDATE = 'checkout-tip-value-update',
  CHECKOUT_ORDER_ERROR = 'checkout-order-error',
  CHECKOUT_LEGAL_PROMPT = 'checkout-legal-prompt',
  CHECKOUT_PROMO_EXIT_INTENT_MODAL_SHOW = 'checkout-promo-exit-intent-modal-show',
  CHECKOUT_PROMO_EXIT_INTENT_MODAL_PROMO_CODE_APPLIED = 'checkout-promo-exit-intent-modal-promo-code-applied',
  CHECKOUT_PROMO_EXIT_INTENT_MODAL_DISMISS = 'checkout-promo-exit-intent-modal-dismiss',

  NEW_USER_EDUCATION_VIEW = 'new-user-education-view',
  NEW_USER_EDUCATION_STEP = 'new-user-education-step',

  CONSUMER_TRIAL_EXPLANATION_VIEW = 'consumer-trial-explanation-view',
  CONSUMER_TRIAL_ONBOARDING_VIEW = 'consumer-trial-onboarding-view',
  CONSUMER_TRIAL_ONBOARDING_ADVANCE = 'consumer-trial-onboarding-advance',
  CONSUMER_TRIAL_ONBOARDING_ACCEPT = 'consumer-trial-onboarding-accept',

  CONSUMER_HEADER_PROMO_BANNER_CLICK = 'consumer-header-promo-banner-click',
  CONSUMER_HEADER_PERSISTENT_PROMO_BANNER_DISMISSED = 'consumer-header-persistent-promo-banner-dismissed',

  COCKTAIL_ID_REMINDER_ACTION = 'cocktail-id-reminder-action', // deprecated 2022-03-11
  COCKTAIL_PURCHASE_ATTEMPT = 'cocktail-purchase-attempt', // deprecated 2022-03-11

  SHEF_FRINGE_MODAL_EXPLANATION_VIEW = 'shef-fringe-modal-explanation-view',
  SHEF_FRINGE_MODAL_MORE_VIEWED = 'shef-fringe-modal-more-viewed',
  SHEF_FRINGE_MODAL_DISMISSED = 'shef-fringe-modal-dismissed',
  SHEF_FRINGE_MODAL_ACCEPTED = 'shef-fringe-modal-accepted',
  SHEF_APPLICATION_TRACKER_SHOW = 'shef-application-tracker-show',
  SHEF_APPLICATION_TRACKER_HIDE = 'shef-application-tracker-hide',
  SHEF_APPLICATION_TRACKER_CLICK_STEP = 'shef-application-tracker-click-step',

  UPSELL_CLICK_ADD_TO_CART = 'upsell-click-add-to-cart', // deprecated 2023-04-19
  UPSELL_CLICK_CONTINUE = 'upsell-click-continue', // deprecated 2023-04-19
  UPSELL_CLICK_NO_THANKS = 'upsell-click-no-thanks', // deprecated 2023-04-19

  ATTEMPTED_REDIRECT_TO_CHROME_IOS = 'attempted-redirect-to-chrome-ios',

  ADD_TO_CALENDAR_CLICK = 'add-to-calendar-click',

  FOLLOW_SHEF_START = 'follow-shef-start',
  FOLLOW_SHEF_STOP = 'follow-shef-stop',
  SHEF_POST_VIEW = 'shef-post-view',
  VIEW_MENU_REVIEWS_SECTION = 'view-menu-reviews-section',
  CLICK_REVIEWS_BUTTON = 'click-reviews-button',
  VIEW_FOOD_SAFETY_POPOVER = 'view-food-safety-popover',
  REVIEW_FOOD_ITEM_IMAGE_CLICK = 'review-food-item-image-click',
  APP_REDIRECT = 'app-redirect',
  NAVIGATE_TO_APP_LISTING = 'navigate-to-app-listing',
  APP_UPSELL_BANNER_DISMISSED = 'app-upsell-banner-dismissed',
  WEDGE_PAGE_VIEW = 'wedge-page-view',
  DELIVERY_DATE_TOOLTIP_OPEN = 'delivery-date-tooltip-open',
  CHAT_WIDGET_OPEN = 'chat-widget-open',
  SHEF_MESSAGE_CLICK = 'meal-plan-shef-message-click',
  FLAGGED_CHAT_MODAL_EVENT = 'flagged-chat-modal-event',

  SINGLE_CART_STARTED_CHECKOUT = 'single-cart-started-checkout',
  MULTI_CART_STARTED_CHECKOUT = 'multi-cart-started-checkout',
  MULTI_CART_ADD_ORDER_BTN_CLICKED = 'multi-cart-add-order-button-clicked', // deprecated 2022-08022
  MULTI_CART_STARTED_NEW_ORDER = 'multi-cart-started-new-order',
  DISH_FIRST_CART_CHANGE_DELIVERY_DATE = 'dish-first-cart-change-delivery-date',
  DISH_FIRST_CLICK_NEXT_DAY_BANNER = 'dish-first-click-next-day-banner',
  DISH_FIRST_LAZY_LOAD_QUICK_ADD_ITEM = 'dish-first-lazy-load-quick-add-item',

  REORDER_BUTTON_CLICK = 'reorder-button-click',
  RECURRING_SUBSCRIPTION_OPTION_SELECT = 'recurring-subscription-option-select',
  RECURRING_SUBSCRIPTION_CANCEL_BUTTON_CLICK = 'recurring-subscription-cancel-button-click',
  RECURRING_SUBSCRIPTION_SKIP_ORDER_BUTTON_CLICK = 'recurring-subscription-skip-order-button-click',

  SUBMIT_DISH_FILTER = 'submit-dish-filter',

  // Browse By Tags
  BROWSE_BY_TAGS_CLICK = 'browse-by-tags-click',

  DELIVERY_DAY_FILTER_ARROW_CLICK = 'delivery-day-filter-arrow-click',
  SEGMENT_ARROW_CLICK = 'segment-arrow-click',
  SEGMENT_SEE_ALL_LINK_CLICK = 'segment-see-all-link-click',
  SEGMENT_ITEM_CLICK = 'clicked-segment-item',

  BROWSE_BY_MEAL_TYPE_CLICK = 'browse-by-meal-type-click',
  BROWSE_BY_SHEF_CLICK = 'browse-by-shef-click',
  CELEBRITY_EVENT_EXPLORE_CLICK = 'celebrity-event-explore-click',
  INDIAN_MARKET_EXPLORE_CLICK = 'indian-market-explore-click',

  BULK_DISCOUNT_EXPLORE_CLICK = 'bulk-discount-explore-click',
  BULK_DISCOUNT_MODAL_VIEW = 'bulk-discount-modal-view',
  BULK_DISCOUNT_MODAL_CLOSE = 'bulk-discount-modal-close',

  // Dish details modal
  DISH_DETAILS_TOGGLE_DETAILS = 'dish-details-toggle-details',
  DISH_DETAILS_SHEF_MENU_SEE_ALL_CLICK = 'dish-details-shef-menu-see-all-click',

  EXPLORE_SEE_MORE_CLICK = 'explore-see-more-click',
  EXPLORE_FOOD_ITEMS_RENDERED = 'explore-food-items-rendered',

  DEVICE_ZIP_TIME_ZONE_DIFFERENCE = 'device-zip-time-zone-difference',

  // Shef Dashboard Analytics
  SHEF_DASHBOARD_MY_PROFILE_CLICK = 'shef-dashboard-my-profile-click',
  SHEF_DASHBOARD_MY_MENU_TAB_CLICK = 'shef-dashboard-my-menu-tab-click',
  SHEF_DASHBOARD_ADD_NEW_DISH_BTN_CLICK = 'shef-dashboard-add-new-dish-btn-click',
  SHEF_DASHBOARD_CREATE_NEW_FOOD_ITEM = 'shef-dashboard-create-new-food-item',
  SHEF_DASHBOARD_FOOD_ITEM_CHANGE_SAVE = 'shef-dashboard-food-item-change-save',
  SHEF_DASHBOARD_MY_RATINGS_TAB_CLICK = 'shef-dashboard-my-ratings-tab-click',
  SHEF_DASHBOARD_SEE_YOUR_REVIEWS_BTN_CLICK = 'shef-dashboard-see-your-reviews-btn-click',
  SHEF_DASHBOARD_PROMOTE_YOURSELF_COPY_LINK_CLICK = 'shef-dashboard-promote-yourself-copy-link-click',
  SHEF_DASHBOARD_ORDERS_BANNER_CLICK = 'shef-dashboard-orders-banner-click',
  SHEF_DASHBOARD_REFERRAL_BANNER_CLICK = 'shef-dashboard-referral-banner-click',
  SHEF_DASHBOARD_REFERRAL_DOWNLOAD_AD_CLICK = 'shef-dashboard-referral-download-ad-click',
  SHEF_DASHBOARD_REFERRAL_MENU_LINK_CLICK = 'shef-dashboard-referral-menu-link-click',
  SHEF_DASHBOARD_REFERRAL_HOW_IT_WORKS_LINK_CLICK = 'shef-dashboard-referral-how-it-works-link-click',
  SHEF_DASHBOARD_REFERRAL_SHARE_TO_FACEBOOK_CLICK = 'shef-dashboard-referral-share-to-facebook-click',
  SHEF_DASHBOARD_REFERRAL_SHARE_TO_WHATSAPP_CLICK = 'shef-dashboard-referral-share-to-whatsapp-click',
  SHEF_DASHBOARD_REFERRAL_SHARE_TO_TWITTER_CLICK = 'shef-dashboard-referral-share-to-twitter-click',
  SHEF_DASHBOARD_REFERRAL_COPY_REFERRAL_CODE_CLICK = 'shef-dashboard-referral-copy-referral-code-click',
  SHEF_DASHBOARD_GROW_YOUR_BUSINESS_TAB_CLICK = 'shef-dashboard-grow-your-business-tab-click',
  SHEF_DASHBOARD_GROW_YOUR_BUSINESS_TAKE_ME_THERE = 'shef-dashboard-grow-your-business-tab-take-me-there',
  SHEF_DASHBOARD_GIVE_GET_PANEL_DETAILS_CLICK = 'shef-dashboard-give-get-panel-details-click',
  SHEF_DASHBOARD_GIVE_GET_PANAL_SHARE_LINK_CLICK = 'shef-dashboard-give-get-panel-share-link-click',
  SHEF_DASHBOARD_GIVE_GET_PANAL_COPY_LINK_CLICK = 'shef-dashboard-grow-your-business-copy-link-click',
  SHEF_DASHBOARD_GROW_YOUR_BUSINESS_SHEF_TO_SHEF = 'shef-dashboard-grow-your-business-shef-to-shef',
  SHEF_PRICE_OUT_OF_RANGE_MODAL = 'shef-price-out-of-range-modal',

  PERSONAL_SHEF_FORM_STEP = 'personal-shef-form-step',
  PERSONAL_SHEF_FORM_SUBMIT = 'personal-shef-form-submit',
  PERSONAL_SHEF_PLAN_CLICK = 'personal-shef-plan-click',
  PERSONAL_SHEF_BANNER_CLICK = 'personal-shef-banner-click',
  PERSONAL_SHEF_LANDING_PAGE_VISITED = 'personal-shef-landing-page-visited',
  PERSONAL_SHEF_LEARN_MORE_CLICK = 'personal-shef-learn-more-click',

  CLICK_HELP_WIDGET = 'click-help-widget',

  // Celebrity Events
  CELEBRITY_EVENT_ERROR = 'celebrity-event-error',

  // 1/10/23 OneSignal push notifications – deprecated. Remove after no events have been logged for a while.
  WEB_PUSH_SUBSCRIPTION_CHANGE = 'web-push-subscription-change',
  WEB_PUSH_PERMISSION_PROMPT_DISPLAY = 'web-push-permission-prompt-display',
  WEB_PUSH_NOTIFICATION_PERMISSION_CHANGE = 'web-push-notification-permission-change',
  WEB_PUSH_POPOVER_SHOWN = 'web-push-popover-shown',
  WEB_PUSH_CUSTOM_PROMPT_CLICK = 'web-push-custom-prompt-click',
  WEB_PUSH_NOTIFICATION_DISPLAY = 'web-push-notification-display',
  WEB_PUSH_NOTIFICATION_DISMISS = 'web-push-notification-dismiss',

  REVIEW_GROUP_ORDER_BUTTON_CLICK = 'review-group-order-button-click',
  GROUP_ORDER_RATING_MODAL_SHOW = 'group-order-rating-modal-show',
  GROUP_ORDER_RATING_MODAL_STEP = 'group-order-rating-modal-step',
  GROUP_ORDER_RATING_MODAL_PARTIAL_SAVE = 'group-order-rating-modal-partial-save',
  GROUP_ORDER_RATING_MODAL_SUBMIT = 'group-order-rating-modal-submit',
  GROUP_ORDER_RATING_MODAL_SUBMIT_SUCCESS = 'group-order-rating-modal-submit-success',
  GROUP_ORDER_RATING_MODAL_SUBMIT_FAILURE = 'group-order-rating-modal-submit-failure',
  RATE_RECENT_GROUP_ORDER_MODAL_SHOW = 'rate-recent-group-order-modal-show',
  RATE_RECENT_GROUP_ORDER_MODAL_CLICK = 'rate-recent-group-order-modal-click',

  WAITLIST_ACTION_CLICKED = 'waitlist-action-clicked',

  BROWSER_TRACKER_RECONCILIATION = 'browser-tracker-reconciliation',
  REFERRAL_REDIRECT = 'referral-redirect',

  FOOD_ITEM_PRICE_RECOMMENDATION = 'food-item-price-recommendation',
  /**
   * Client events that are specific only to the native app.
   *
   * DO NOT USE THESE EVENTS FOR SHEF-MONO.
   *
   * We need this here for now until we figure out a way to leverage the same analytics code
   * for both shef-mono and consumer-app.
   */
  TRACKING_PERMISSION_REQUESTED = 'tracking-permission-requested',

  PUSH_NOTIFICATION_OPEN = 'push-notification-open',
  PUSH_NOTIFICATION_PROMPT_SHOW = 'push-notification-prompt-show',
  PUSH_NOTIFICATION_PROMPT_HIDE = 'push-notification-prompt-hide',
  PUSH_NOTIFICATION_PERMISSION_REQUESTED = 'push-notification-permission-requested',

  APP_REVIEW_REQUEST_ATTEMPT = 'app-review-request-attempt',
  APP_REVIEW_REQUEST_FAILED = 'app-review-request-failed',
  APP_REVIEW_REQUEST_SKIPPED = 'app-review-request-skipped',
  APP_REVIEW_REQUESTED = 'app-review-requested',

  LOCATION_SOURCE_SELECTED = 'location-source-selected',
  LOCATION_PERMISSION_REQUESTED = 'location-permission-requested',
  LOCATION_SET = 'location-set',

  PRE_CHECKOUT_UPSELL_MODAL_VIEW = 'pre-checkout-upsell-modal-view',
  PRE_CHECKOUT_UPSELL_MODAL_CLOSE = 'pre-checkout-upsell-modal-close',
  PRE_CHECKOUT_UPSELL_ADD_ALL = 'pre-checkout-upsell-add-all',
  PRE_CHECKOUT_UPSELL_REFRESH_SUGGESTIONS = 'pre-checkout-upsell-refresh-suggestions',
  /** End native app events */
}

export enum DeprecatedClientEvents {
  SEARCH = 'search',
  SELECT_DELIVERY_DAY_SHEF = 'select-delivery-day-shef',
  SEARCH_TEXT_CHANGED = 'search-text-changed',
}

export interface DeprecatedClientTrackableEvents
  extends Record<DeprecatedClientEvents, JSONObj>,
    TrackableSharedEvents {
  // Description: ?
  // Deprecated (when?)
  [DeprecatedClientEvents.SEARCH_TEXT_CHANGED]: {
    text: string;
  };
  // Description: ?
  // Deprecated.
  [DeprecatedClientEvents.SELECT_DELIVERY_DAY_SHEF]: {
    shefId: string;
    date: string;
  };
  [DeprecatedClientEvents.SEARCH]: {
    // Deprecated: 2020-03-3
    // Description: Use to be fired when a user entered in their zipcode on our landing page. This is now LANDING_ZIP_SUBMIT
    value: string;
    page: string;
  };
}

export type PurchaseData = {
  rank?: number;
  esid?: string; // explore segment id
  promoCode?: string;
  promoCodeType?: string;
  promoCodeReferralType?: 'consumer' | 'shef';
  promoCodeValue?: number;
  promoCodeIsPercentage?: boolean;
  promoCodeDescription?: string;
  // shefId: string; -- deprecated 2022-10-10
  shefIds: string[];
  subtotal: number;
  shefTip: number | null;
  paymentMethod: string;

  cuisineRegion?: string | null;
  organizationName: string | null;
  // orderId?: number; -- deprecated 2022-10-10
  groupOrderId: string;
  orderNumber: number;
};

type PurchaseItemData = {
  foodItemId: number;
  shefId: string;
  quantity: number;
};

export interface ClientTrackableEvents
  extends Record<ClientEvents, ExceptFields<JSONObj, 'source'>>,
    TrackableSharedEvents {
  [ClientEvents.CHECKOUT_DELIVERY_ADDRESS_SELECT]: JSONObj;
  [ClientEvents.CHECKOUT_DELIVERY_TIME_SELECT]: JSONObj;
  [ClientEvents.CHECKOUT_CREDIT_CARD_INPUT]: JSONObj;
  [ClientEvents.CHECKOUT_TIP_VALUE_UPDATE]: {
    tipValue: number;
  };
  [ClientEvents.AUTO_APPLIED_REWARD_PROMO]: {
    code: string;
    promoCodeType: string;
    value: number;
    isPercentage: boolean;
    promoCodeDescription: string;
  };
  [ClientEvents.CHECKOUT_ORDER_ERROR]: {
    errors: string[];
    productType: 'marketplace' | 'subscriptions';
  };
  [ClientEvents.CHECKOUT_LEGAL_PROMPT]: {
    hasTermsOfService: boolean;
    hasSmsMarketingOptIn: boolean;
  };
  [ClientEvents.CHECKOUT_PROMO_EXIT_INTENT_MODAL_SHOW]: {
    promoCode: string;
    value: number;
    promoCodeIsPercentage: boolean;
    promoCodeDescription: string;
  };
  [ClientEvents.CHECKOUT_PROMO_EXIT_INTENT_MODAL_DISMISS]: {
    promoCode: string;
    value: number;
    promoCodeIsPercentage: boolean;
    promoCodeDescription: string;
  };
  [ClientEvents.CHECKOUT_PROMO_EXIT_INTENT_MODAL_PROMO_CODE_APPLIED]: {
    promoCode: string;
    value: number;
    promoCodeIsPercentage: boolean;
    promoCodeDescription: string;
  };
  // Description: When user attempts to sign up from the register page.
  [ClientEvents.SIGN_UP_ATTEMPT]: {
    method: AuthMethod;
  };
  [ClientEvents.SIGN_UP_ERROR]: {
    // Description: When user receives an error while registering
    method: AuthMethod; // Google, Facebook, Email etc.
    errors: string[]; // user-facing error message
  };
  [ClientEvents.FOOD_SEARCH]: {
    // Description:
    value: string; // Current text in field
    numResults: number; // TODO: How many results were returned. Currently hard-coded to 0.
  };
  [ClientEvents.FOOD_SEARCH_RESULTS_DISPLAYED]: {
    // Description: Tracks the search results shown
    query: string; // Search term / query string
    shef_results: string[];
    food_item_results: number[];
    cuisine_results: string[];
  };
  [ClientEvents.SHEF_SEARCH_RESULT_CLICK]: {
    // Description: When user clicks on a search result that is a Shef
    shefId: string;
    search: string; // User's search query
  };
  [ClientEvents.FOOD_SEARCH_RESULT_CLICK]: {
    // Description: When user clicks on a search result that is a dish.
    foodItemId: number;
    foodName: string | null;
    search: string; // User's search query
  };
  [ClientEvents.CUISINE_SEARCH_RESULT_CLICK]: {
    // Description: When user clicks on a search result that is a cuisine.
    cuisineCategoryId: string;
    search: string; // User's search query
  };
  [ClientEvents.SEARCH_SHOW_MORE_SHEFS_CLICK]: {
    // Description: When user clicks "show more" shef results
    search: string; // User's search query
  };
  [ClientEvents.SEARCH_SHOW_MORE_DISHES_CLICK]: {
    // Description: When user clicks "show more" dish results
    search: string; // User's search query
  };
  [ClientEvents.MORE_CUISINES_CLICK]: {
    cuisineCategoryId: string;
    ancestorCuisineCategoryId: string;
  };
  [ClientEvents.GIFT_CARD_SEARCH_RESULT_CLICK]: {
    search: string; // User's search query
  };
  // Description: When a user clicks a trending search from the search dropdown
  [ClientEvents.TRENDING_SEARCH_CLICK]: {
    query: string; // Trending search term
  };
  // Description: When user adds something to the cart
  [ClientEvents.CART_ADD_ITEM]: {
    id: number;
    price: number;
    foodType: string | null;
    vegan: boolean | null;
    vegetarian: boolean | null;
    lowFodmap: boolean | null;
    paleo: boolean | null;
    whole30: boolean | null;
    glutenFree: boolean | null;
    dairyFree: boolean | null;
    containsNuts: boolean | null;
    isPopular: boolean | null;
    compostable: boolean;
    servingSizeCustomizationIds: string[];
    spiceLevelCustomizationIds: string[];

    location?: string;
  };

  // Description: When user removes something from their cart
  [ClientEvents.CART_REMOVE_ITEM]: {
    id: number;
    price: number;
    foodType: string | null;
    vegan: boolean | null;
    vegetarian: boolean | null;
    lowFodmap: boolean | null;
    paleo: boolean | null;
    whole30: boolean | null;
    glutenFree: boolean | null;
    dairyFree: boolean | null;
    containsNuts: boolean | null;
    isPopular: boolean | null;
    compostable: boolean;
    servingSizeCustomizationIds: string[];
    spiceLevelCustomizationIds: string[];

    location?: string;
  };
  // Description: When user adds something to their cart via the pop-up modal to get them to $25 free delivery
  [ClientEvents.FREE_DELIVERY_ADD_ITEM]: Partial<{
    id: number;
    price: number;
    foodType: string | null;
    vegan: boolean | null;
    vegetarian: boolean | null;
    lowFodmap: boolean | null;
    paleo: boolean | null;
    whole30: boolean | null;
    glutenFree: boolean | null;
    dairyFree: boolean | null;
    containsNuts: boolean | null;
    isPopular: boolean | null;
  }>;
  // Description: When user purchases an item
  [ClientEvents.PURCHASE]: PurchaseData & {
    items: PurchaseItemData[];
  };
  [ClientEvents.EXISTING_USER_PURCHASE]: PurchaseData;
  [ClientEvents.NEW_USER_PURCHASE]: PurchaseData;
  // Description: When user clicks the purchase button. Before errors are shown
  [ClientEvents.PURCHASE_ATTEMPT]: {
    rank?: number;
    esid?: string; // explore segment id
    promoCode?: string;
    promoCodeType?: string;
    promoCodeIsPercentage?: boolean;
    promoCodeValue?: number;
    promoCodeDescription?: string;
    shefIds: string[];
    subtotal: number;
    shefTip: number | null;
    paymentMethod: string;

    cuisineRegion?: string | null;
    organizationName: string | null;
  };
  // Description: When user clicks the purchase button. Before errors are shown
  [ClientEvents.PURCHASE_ATTEMPT_FAILED]: {
    rank?: number;
    esid?: string; // explore segment id
    promoCode?: string;
    promoCodeType?: string;
    promoCodeIsPercentage?: boolean;
    promoCodeValue?: number;
    promoCodeDescription?: string;
    shefIds: string[];
    subtotal?: number;
    shefTip?: number | null;
    paymentMethod: string;
    productType: 'marketplace' | 'subscriptions';
    cuisineRegion?: string | null;
    organizationName?: string | null;

    community?: string;
    discounts?: number;
    addOnsTotal?: number;
    taxesAndFees?: number;
    taxes?: number;
    fees?: number;
    finalPrice?: number;

    badUserInput?: boolean;
    errorMessage?: string;
  };
  // Description: Fired client side after a shef application is sucessfully submitted
  [ClientEvents.SHEF_APPLICATION_RECEIVED]: {
    firstName: string;
    lastName?: string | null;
    zipcode: string;
    email: string;
    phone: string;
    cuisines?: string | null;
    referral?: string | null;
  };
  // Description: Fired client side when a user lands on our website with a URL from a valid organization (company)
  [ClientEvents.ORGANIZATION_LANDING]: {
    zipcode?: string;
    organizationName: string;
  };
  // Description: When a referral code is copied to the clipboard
  [ClientEvents.REFERRAL_CODE_COPIED]: {
    code: string;
  };
  // Description: When a user's web page has been automatically reloaded due to out of sync code.
  [ClientEvents.API_SYNC_RELOAD]: JSONObj;
  // Description: When user has clicked to share a referral code. We do not know if they completed the action
  // as that's in the native share widget / on a separate webpage.
  [ClientEvents.REFERRAL_CODE_SHARED]: {
    code: string;
    shefId?: string;
    type: ShareType; // e.g. FB, twitter, etc.
    location: ReferralShareLocation; // Location it's shared from
  };
  // Description: When a shef has clicked to share a food item.
  [ClientEvents.SHARE_SHEF_FOOD_ITEM]: {
    foodItemId: number;
    shefId: string;
  };
  // Description: When a shef has clicked to share a link to their menu.
  [ClientEvents.SHARE_SHEF_MENU]: {
    shefId: string;
  };
  // Description: When a shef has clicked to share a custom promo code they made.
  [ClientEvents.SHARE_SHEF_PROMO_CODE]: {
    shefId: string;
    code: string;
    promoCodeIsPercentage: boolean;
    value: number;
    promoCodeDescription: string;
  };
  // Ignore.
  [ClientEvents.SHARED_NONE]: JSONObj;

  // Description: When a user session has started. Sessions are at most 30 minutes, and per browser tab.
  [ClientEvents.SESSION_STARTED]: {
    startedAt: number;
    referrer: string | undefined;
    gclid: string | undefined;
    language: string;
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
    utmTarget?: string;
    sessionId: string;
  };
  // Description: When a user has landed on the shef application page
  [ClientEvents.BECOME_A_SHEF_VISIT]: JSONObj;

  // Description: All page views
  [ClientEvents.PAGE_VIEW]: {
    // Description: the full path of the current URL, like /explore/zip/91290?utm_campaign=new
    route: string;
    queryParams: JSONType | null;

    // Description: "92190" for the URL "/explore/zip/91290" when matching the pattern in "/explore/zip/:zip_code"
    params: JSONType | null;

    // Description: the URL pattern we used to route the consumer with, like "/explore/zip/:zip_code"
    pattern: string | null;

    // Description: the preferred color theme of the user's browser on page view.
    preferredColorTheme: ColorTheme | null;

    // Description: Dimensions of the user's viewport
    viewportDimensions: {
      widthPixels: number;
      heightPixels: number;
    };
  };
  // Description: user has changed the delivery days they want to filter results for.
  [ClientEvents.SUBMIT_DELIVERY_DAY_FILTER]: {
    dates: string[];
  };
  // Description: user opened the dietary filter options.
  [ClientEvents.CLICK_DIETARY_FILTER]: JSONObj;
  // Description: user has changed the dietary restrictions they want to filter results for.
  [ClientEvents.SUBMIT_DIETARY_FILTER]: {
    dietaryTags: string[];
  };
  // Description: user clicks on spice level filter options
  [ClientEvents.CLICK_SPICE_FILTER]: JSONObj;
  // Description: user has changed their spice level filters
  [ClientEvents.SUBMIT_SPICE_FILTER]: {
    spiceLevels: string[];
  };
  // Description: user has changed the cuisine categories they want to filter results for.
  [ClientEvents.SUBMIT_CUISINE_CATEGORY_FILTER]: {
    cuisineCategories: string[];
  };
  // Description: user has changed their cuisine filter
  [ClientEvents.SELECT_CUISINE_FILTER]: {
    segmentFilterSelected: string | null;
    subcuisineFilterSelected?: string | null;
    elementName?: string | null;
  };

  // Description: user clicks on pickup distance filter options
  [ClientEvents.CLICK_PICKUP_DISTANCE_FILTER]: Record<string, never>;
  // Description: user has changed their pickup distance filter
  // deprecated 11-29-2022
  // [ClientEvents.SUBMIT_PICKUP_DISTANCE_FILTER]: {
  //   pickupDistance: number | null;
  // };

  // Description: When user clicks the < and > arrows on a shef on the explore page to see other dishes.
  [ClientEvents.CAROUSEL_SWIPE]: {
    direction: string;
  };
  // Description: When user submits a zipcode on the landing page
  [ClientEvents.LANDING_ZIP_SUBMIT]: {
    zip: string;
    queryParams?: Record<string, string>;
  };
  // Description: When user submits a zipcode on the explore page
  [ClientEvents.EXPLORE_ZIP_SUBMIT]: {
    zip: string;
  };
  // Description: When user clicks to watch a video on a shef's profile
  [ClientEvents.VIDEO_BIO_CLICK]: {
    isCoverImage: boolean;
    shefId: string;
  };
  // Description: When user clicks the "more" link in a shef's bio to read the full bio.
  [ClientEvents.LEARN_MORE_CLICK]: {
    shefId: string;
  };

  // Description: When user clicks the + icon on a food item to increase quantity in cart
  [ClientEvents.CART_INCREMENT]: {
    shefId: string | null;
    itemId: string | number;
  };
  // Description: When user clicks the - icon on a food item to decrease quantity in cart
  [ClientEvents.CART_DECREMENT]: {
    shefId: string | null;
    itemId: string | number;
  };
  // Description: When user clicks a card in the shef spotlight section
  [ClientEvents.SHEF_SPOTLIGHT_CLICK]: {
    shefId: string | null;
  };
  // Description: When user clicks a card in the recently reviewed section
  [ClientEvents.RECENTLY_REVIEWED_CLICK]: {
    shefId: string | null;
  };

  // Description: When user attempts to login.
  [ClientEvents.LOGIN_ATTEMPT]: {
    method: AuthMethod;
  };

  // Description: When user fails to login.
  [ClientEvents.LOGIN_FAILED]: {
    method?: AuthMethod;
    errorMessage: string;
  };

  // Description: When a request for single-sign on authentication has succeeded.
  [ClientEvents.SSO_AUTH_SUCCESS]: {
    method: AuthMethod;
  };

  // Description: When user clicks on hambuger menu in mobile
  [ClientEvents.NAV_HAMBUGER_MENU_CLICK]: Record<string, never>;

  // Description: When user clicks on an item in the nav bar
  [ClientEvents.NAV_CLICK]: {
    elementName: NavClickElementName;
  };

  // Description: When user visits a menu page
  [ClientEvents.SHEF_MENU_VISIT]: Record<string, never>;

  // Description: When user visits a menu item dialog
  [ClientEvents.DISH_MODAL_VISIT]: {
    type: 'description-legacy' | 'customizations' | 'dish-first';
    productType: 'subscriptions' | 'marketplace';
    dishServingSizeOptionsCount: number;
    dishSpiceLevelOptionsCount: number;
    foodItemId: number;
  };

  // Description: When user clicks the Shef profile image on a menu page (mobile only)
  [ClientEvents.SHEF_PROFILE_IMAGE_CLICK]: {
    shefId: string;
  };

  // Description: When user clicks the food image on a menu item dialog (mobile only)
  [ClientEvents.DISH_IMAGE_CLICK]: {
    foodItemId: number;
  };

  // Description: When user clicks on a prefab food item
  [ClientEvents.PREFAB_FOOD_ITEM_COPY]: {
    originalFoodItem: JSONObj;
    newFoodItem: JSONObj;
    shefId?: string;
    searchValue?: string;
    suggestionRank?: number;
  };

  [ClientEvents.REORDER_BUTTON_CLICK]: {
    shefId: string;
    orderId: number;
  };

  [ClientEvents.RECURRING_SUBSCRIPTION_OPTION_SELECT]: {
    shefId?: string;
    recurringPeriod: string;
  };

  [ClientEvents.RECURRING_SUBSCRIPTION_CANCEL_BUTTON_CLICK]: {
    subscriptionId?: string;
  };

  [ClientEvents.RECURRING_SUBSCRIPTION_SKIP_ORDER_BUTTON_CLICK]: {
    groupOrderId: string;
  };

  // Description: When user submits filters on Filters modal
  [ClientEvents.SUBMIT_DISH_FILTER]: {
    cuisineFilters: string[] | undefined;
    dietaryFilters: string[] | undefined;
    isEcoFriendlyPackaging: boolean | undefined;
    minRatingFilter: number | undefined;
    priceLevelFilters: string[] | undefined;
    proteinFilters: string[] | undefined;
    segmentFilters: string[] | undefined;
    spiceLevelFilters: string[] | undefined;
  };

  // Description: When user clicks on a collection in the "Browse by collection" section
  [ClientEvents.BROWSE_BY_TAGS_CLICK]: {
    tag: string;
    type: BrowseByTagsType;
    position: number;
  };

  // Description: When user clicks on a collection in the "Browse by meal type" section
  [ClientEvents.BROWSE_BY_MEAL_TYPE_CLICK]: {
    location: string;
    mealType: string;
    position: number;
    segmentFilters: string[] | undefined;
    pattern: string | null;
  };

  // Description: Triggers when user click the browse by shef mini banner
  [ClientEvents.BROWSE_BY_SHEF_CLICK]: Record<string, never>;

  // Description: Triggers when user click the bulk discount explore banner
  [ClientEvents.BULK_DISCOUNT_EXPLORE_CLICK]: Record<string, never>;

  // Description: Triggers when user sees tbe bulk discount modal
  [ClientEvents.BULK_DISCOUNT_MODAL_VIEW]: Record<string, never>;

  // Description: Triggers when user hides the bulk discount modal
  [ClientEvents.BULK_DISCOUNT_MODAL_CLOSE]: Record<string, never>;

  // Description: When user clicks on the scroll arrow in the date picker
  [ClientEvents.DELIVERY_DAY_FILTER_ARROW_CLICK]: {
    direction: 'right' | 'left';
  };

  // Description: When user clicks on segment arrows on explore
  [ClientEvents.SEGMENT_ARROW_CLICK]: {
    direction: 'right' | 'left';
    elementName: string;
  };

  // Description: When user clicks on the See All button on an explore segment
  [ClientEvents.SEGMENT_SEE_ALL_LINK_CLICK]: {
    segmentId: string;
    segmentRenderedItems: number;
    segmentTotalItems: number;
    position: number;
    type: 'shef' | 'dish';
    filters: {
      spiceFilters: string[] | undefined | null;
      cuisineFilters: string[] | undefined | null;
      dietaryFilters: string[] | undefined | null;
      dishTypeFilters: string[] | undefined | null;
      priceFilters: string[] | undefined | null;
      dateFilters: string[] | undefined | null;
      proteinFilters: string[] | undefined | null;
    };
  };
  [ClientEvents.SEGMENT_ITEM_CLICK]: {
    segmentId: string;
    segmentRenderedItems: number;
    segmentTotalItems: number;
    position: number;
    type: 'shef' | 'dish';
    rank: number;
    filters: {
      spiceFilters: string[] | undefined | null;
      cuisineFilters: string[] | undefined | null;
      dietaryFilters: string[] | undefined | null;
      dishTypeFilters: string[] | undefined | null;
      priceFilters: string[] | undefined | null;
      dateFilters: string[] | undefined | null;
      proteinFilters: string[] | undefined | null;
    };
  };

  [ClientEvents.DISH_DETAILS_SHEF_MENU_SEE_ALL_CLICK]: Record<string, never>;

  [ClientEvents.EXPLORE_SEE_MORE_CLICK]: Record<string, never>;

  [ClientEvents.EXPLORE_FOOD_ITEMS_RENDERED]: {
    // Description: When an explore segment is being sent down to the client to be rendered.
    // If any segment filters were applied at the time of rendering
    segmentFilters: string[] | undefined;
    // The segment id, e.g. shef-of-the-week, next-day, etc
    esid: string;
    // The position that the section should appear on the client (assuming we
    // render the segments in order). Offset is added to make position independent
    // of someone using infinite scroll or not
    pos: number;
    // The SegmentLayout used. e.g. GENERIC, HORIZONTAL_SCROLL, etc
    layout: string;
    // List of items rendered in the segment
    itemIds: string[];
    // The total number of possible elements we can show (esti)
    total: number;
    // Whether or not the segment is for dishes
    isDishSegment: boolean;
    // The current delivery day
    deliveryDay: string;
  };

  [ClientEvents.DEVICE_ZIP_TIME_ZONE_DIFFERENCE]: {
    zipCode: string;
    zipCodeTimeZone: string;
    deviceTimeZone: string;
    offset: number; // ms offset between the two timezones
  };

  // Description: When user views suggested prefab food items
  [ClientEvents.FOOD_ITEM_SUGGESTIONS_VIEW]: {
    shefId: string;
    foodItemSuggestions: {
      foodItemId: number;
      foodItemName?: string;
      suggestionRank: number;
    }[];
  };

  [ClientEvents.AUTO_APPLIED_PROMO]: {
    promoCode: string;
    promoCodeType: string;
    promoCodeIsPercentage: boolean;
    value: number;
    promoCodeDescription: string;
  };

  // Description: A promo code has been applied from the url.
  [ClientEvents.AUTO_APPLIED_SUBSCRIPTION_PROMO]: {
    promoCode: string;
    promoCodeIsPercentage: boolean;
    value: number;
    promoCodeDescription: string;
  };
  // Description: Fires each time the side pairing modal is opened (by ATC action)
  [ClientEvents.MEAL_PLAN_SIDE_MODAL_SHOWN]: {
    mainFoodItemId: number;
    format: string;
  };

  // Description: Fires once for each shef rendered in the sides modal, with a property with an array of food items.
  [ClientEvents.MEAL_PLAN_SIDE_MODAL_SHEF_RENDERED]: {
    shefId: string;
    mainFoodItemId: number;
    path: string;
    foodItemIds: number[];
    format: string;
  };

  // Description: Fires each time user clicks the “review order”
  // CTA on dish selection, which brings up the review order view
  [ClientEvents.MEAL_PLAN_REVIEW_ORDER_SHOWN]: {
    format: string;
  };

  // Description:Fires if user exits out of review order without proceeding to checkout page
  [ClientEvents.MEAL_PLAN_REVIEW_ORDER_CLOSED]: Record<string, never>;

  // Description: fires every time user interacts with the mains / sides tab
  [ClientEvents.MEAL_PLAN_DISH_TYPE_NAVIGATION]: {
    dishType: string;
    path: string;
    format: string;
  };

  // Description: New property for "format" to indicate flexible_sides vs. mains_plus_sides
  [ClientEvents.MEAL_PLANNING_MVP_INTAKE_FORM_STEP]: {
    format: string;
    step: string;
  };

  // Description: any click to get to register screen on become a shef
  [ClientEvents.BECOME_A_SHEF_CLICK_GET_STARTED]: {
    section: string;
    phone?: string;
  };

  // Description: When user clicks the purchase button for a subscription. Before errors are shown
  [ClientEvents.SUBSCRIPTION_PURCHASE_ATTEMPT]: {
    cuisinePreferences: Record<string, boolean>;
    dietaryRestrictions: Record<string, boolean>;
    selectedSubscriptionPriceId: string;
    foodItemOrderData: {
      foodItemId: number;
      deliveryDay: string;
      quantity: number;
    }[];
    promoCode: string;
    promoCodeIsPercentage: boolean;
    promoCodeValue: number;
    promoCodeDescription: string;
    paymentMethod: string;
    subtotal: number;
    mondayDelivery: boolean;
    tuesdayDelivery: boolean;
    wednesdayDelivery: boolean;
    thursdayDelivery: boolean;
    fridayDelivery: boolean;
    saturdayDelivery: boolean;
    sundayDelivery: boolean;
  };

  // Description: When user clicks the purchase button for a subscription. Before errors are shown
  [ClientEvents.SUBSCRIPTION_MEAL_DETAILS_CLICK]: {
    foodItemId: number;
    currentPath: string;
  };

  // Description: When user purchases an item
  [ClientEvents.SUBSCRIPTION_PURCHASE]: {
    cuisinePreferences: Record<string, boolean>;
    dietaryRestrictions: Record<string, boolean>;
    selectedSubscriptionPriceId: string;
    foodItemOrderData: {
      foodItemId: number;
      deliveryDay: string;
      quantity: number;
    }[];
    promoCode: string;
    promoCodeIsPercentage: boolean;
    promoCodeValue: number;
    promoCodeDescription: string;
    paymentMethod: string;
    subtotal: number;
    subscriptionId: string;
  };

  [ClientEvents.MEAL_PLANNING_MVP_FOOD_ITEM_RENDERED]: {
    shefId: string;
    foodItemIds: number[];
    cuisines: string[];
    path: string;
    format: string;
  };
  [ClientEvents.MEAL_PLANNING_MVP_MY_CUISINE_RESULTS]: {
    numShefsAvailable: number;
    numDishesAvailable: number;
    deliveryDay: string;
    deliveryDayOfWeek: number;
    dietaryTags?: string;
    dietaryFilters?: string[];
    dietaryRestrictions?: string[];
    dietType?: string;
    userId: string;
    cuisines: string[];
    path: string;
    regionId: number;
    zipCode: string;
  };

  [ClientEvents.MANAGE_SUBSCRIPTION_DROPDOWN]: {
    action: string;
  };

  [ClientEvents.MEAL_PLANNING_MVP_IMPERFECT_MATCH_RESULTS]: {
    numShefsAvailable: number;
  };
  [ClientEvents.MEAL_PLANNING_MVP_MORE_SHEFS_CLICK]: {
    numClicks: number;
  };
  [ClientEvents.MEAL_PLANNING_MVP_DISH_UPDATE_ATTEMPT]: {
    format: string;
  };

  [ClientEvents.MEAL_PLANNING_MVP_ADD_TO_CART]: {
    shefId: string;
    foodItemId: string;
    shefPosition: number;
    foodItemPosition: number;
    path: string;
    format: string;
    location: CartChangeLocation;
  };

  [ClientEvents.MEAL_PLAN_OPEN_CART]: {
    page: string;
    step: string;
    cta_state: string;
    format: string;
  };

  [ClientEvents.MEAL_PLAN_NOT_AVAILABLE_ERROR]: {
    zipCode: string;
    mealPlansEnabled: boolean;
    isInMealPlanRegion: boolean;
    community: string;
    userId: string;
  };

  [ClientEvents.MEAL_PLANNING_MVP_REMOVE_FROM_CART]: {
    shefId: string;
    foodItemId: string;
    shefPosition: number;
    foodItemPosition: number;
    format: string;
    path: string;
    location: CartChangeLocation;
  };

  [ClientEvents.MEAL_PLANNING_MVP_SIDE_OR_STAPLE_ADD]: {
    item: number;
    path?: string;
    location: CartChangeLocation;
    format: string;
  };

  [ClientEvents.MEAL_PLANNING_MVP_CHECKOUT_STARTED]: {
    community: string;
    weeklySubtotal: number;
    discounts: number;
    addOnsTotal: number;
    taxes: number;
    fees: number;
    finalPrice: number;
    format: string;
  };

  [ClientEvents.MEAL_PLANNING_MVP_DELIVERY_ADDRESS_SELECT]: {
    community: string;
  };

  [ClientEvents.MEAL_PLANNING_MVP_DELIVERY_TIME_SELECT]: {
    format: string;
    community?: string;
  };

  [ClientEvents.MEAL_PLANNING_MVP_CREDIT_CARD_INPUT]: {
    community: string;
  };

  [ClientEvents.MEAL_PLANNING_MVP_PURCHASE]: {
    format: string;
    community: string;
    weeklySubtotal: number;
    discounts: number;
    addOnsTotal: number;
    taxes: number;
    fees: number;
    finalPrice: number;
  };

  [ClientEvents.MEAL_PLANNING_MVP_PURCHASE_ATTEMPT]: {
    format: string;
    community: string;
    weeklySubtotal: number;
    discounts: number;
    addOnsTotal: number;
    taxes: number;
    fees: number;
    finalPrice: number;
  };

  [ClientEvents.MEAL_PLANNING_MVP_SKIP_DELIVERY_ATTEMPT]: {
    community: string;
    format: string;
    deliveryDate?: string;
    deliveryNumber?: number;
  };
  [ClientEvents.MEAL_PLANNING_UPCOMING_ORDER_RENDERED]: {
    isUpcoming: boolean;
    upcomingOrderPageStatus: string;
    format: string;
    deliveryDate?: string;
    deliveryNumber?: number;
    userId?: string;
  };
  [ClientEvents.MEAL_PLANNING_UNSKIP_DELIVERY]: {
    userId: string;
    format: string;
    deliveryDate?: string;
    deliveryNumber?: number;
  };
  [ClientEvents.MEAL_PLANNING_UNSKIP_DELIVERY_ERROR]: {
    userId: string;
    format: string;
    deliveryDate?: string;
    deliveryNumber?: number;
  };
  [ClientEvents.MEAL_PLANNING_UPCOMING_ORDER_BTN_CLICK]: {
    action: string;
    format?: string;
    deliveryDate?: string;
    deliveryNumber?: number;
  };
  [ClientEvents.MEAL_PLANNING_EDIT_DELIVERY_DATE_BTN_CLICK]: {
    action: string;
  };
  [ClientEvents.MEAL_PLANNING_UPCOMING_ORDER_PAGE_VIEW]: {
    status: string;
    format: string;
  };
  [ClientEvents.MEAL_PLANNING_PREFERENCES_SUBMITTED]: {
    mealsPerWeek: number;
    servingsPerMeal: number;
    dietaryPreferences?: string[];
    dietaryRestrictions?: string[];
    dietaryFilters?: string[];
    proteinPreferences?: string[];
    deliveryDate: string;
    oilinessPreferencePct?: number;
    adventureLevelPct?: number;
    allergens?: string[] | null;
    spiceLevels?: string[] | null;
    cuisinePreferences?: string[] | null;
    moreInfo?: string;
  };
  [ClientEvents.EXPLORE_PERSONAL_MEAL_PLAN_BANNER_SHOWN]: {
    hasMealPlan: boolean;
    regionId: number;
    currentUrl: string;
  };
  [ClientEvents.EXPLORE_PERSONAL_MEAL_PLAN_BANNER_CLICKED]: {
    hasMealPlan: boolean;
    regionId: number;
    currentUrl: string;
  };
  [ClientEvents.MEAL_PLANNING_EDIT_PREFERENCE_STARTED]: {
    path: string;
    preferenceType: string;
  };
  [ClientEvents.MEAL_PLANNING_EDIT_PREFERENCE_SAVED]: {
    path: string;
    preferenceType: string;
  };
  [ClientEvents.MEAL_PLAN_SHEF_BIO_CLICK]: {
    shefId: string;
    placement: ShefBioPlacements;
  };
  [ClientEvents.INTAKE_HOUSEHOLD]: {
    numAdults?: number;
    numBigEaters?: number;
    numChildren?: number;
  };
  [ClientEvents.INTAKE_PORTION_SIZE_SELECTED]: {
    portionSelection: string;
    mealPlanTypeId: number;
  };
  [ClientEvents.MEAL_PLAN_ANYTHING_ELSE_TO_KNOW]: {
    entryPoint: string;
    text: string;
  };
  [ClientEvents.MEAL_PLAN_FILTERS_APPLIED]: {
    proteins: string[];
    dietType: string;
    dietaryFilters: string[];
    dietaryRestrictions: string[];
  };
  [ClientEvents.MEAL_PLAN_EDIT_TASTE_PROFILE_CLICKED]: {
    entryPoint: string;
  };
  [ClientEvents.MEAL_PLAN_TASTE_PROFILE_EDITED]: {
    dietType: string;
    preferredProteins: string[];
    dietaryFilters: string[];
    dietaryRestrictions: string[];
    cuisinePreferences: string[];
  };
  [ClientEvents.MEAL_PLAN_CART_ITEM_NOT_RETURNED]: {
    tasteProfileObj: string;
  };
  [ClientEvents.HOMEPAGE_MEAL_PLAN_CLICK]: {
    placement: string;
  };
  [ClientEvents.HOMEPAGE_MARKETPLACE_CLICK]: {
    placement: string;
  };
  [ClientEvents.MEAL_PLAN_TASTE_PROFILE_INTAKE]: {
    dietType: string;
    preferredProteins: string[];
    dietaryFilters: string[];
    dietaryRestrictions: string[];
  };

  // Description: first change to the become a shef form
  [ClientEvents.BECOME_A_SHEF_ENTER_INFO]: Record<string, never>;

  [ClientEvents.BECOME_A_SHEF_CLICK_SIGN_UP]: Record<string, never>;

  [ClientEvents.REGISTER_SUCCESS]: {
    method?: AuthMethod;
    promoCode?: string;
  };
  [ClientEvents.LOGIN_MODAL_OPENED]: {
    signUpEntryPoint?: string | null;
  };
  [ClientEvents.LOGIN_MODAL_DISMISSED]: {
    signUpEntryPoint?: string | null;
    element: LoginModalDismissElements;
  };
  [ClientEvents.LOGIN_MODAL_EMAIL_ENTERED]: {
    signUpEntryPoint?: string | null;
  };
  [ClientEvents.SSO_SIGNUP_CLICKED]: {
    sso_name: string;
  };

  // Description: "How it Works" button
  [ClientEvents.NEW_USER_EDUCATION_VIEW]: Record<string, never>;
  [ClientEvents.NEW_USER_EDUCATION_STEP]: {
    step: string;
    videoProgress?: number;
  };

  [ClientEvents.CONSUMER_TRIAL_EXPLANATION_VIEW]: Record<string, never>;
  [ClientEvents.CONSUMER_TRIAL_ONBOARDING_VIEW]: {
    location: 'explore' | 'profile' | 'menu';
  };
  [ClientEvents.CONSUMER_TRIAL_ONBOARDING_ADVANCE]: {
    page: number;
  };
  [ClientEvents.CONSUMER_TRIAL_ONBOARDING_ACCEPT]: Record<string, never>;
  [ClientEvents.CONSUMER_HEADER_PROMO_BANNER_CLICK]: Record<string, never>;

  // Description: User response to CocktailIdReminderModal
  [ClientEvents.CONSUMER_HEADER_PERSISTENT_PROMO_BANNER_DISMISSED]: {
    promoCode: string;
    promoCodeIsPercentage: boolean;
    value: number;
    promoCodeDescription: string;
  };

  // Description: User response to CocktailIdReminderModal
  /** @deprecated 2022-03-11 */
  [ClientEvents.COCKTAIL_ID_REMINDER_ACTION]: {
    action: 'accept' | 'decline';
  };

  // Description: User attempt to submit cocktails order
  /** @deprecated 2022-03-11 */
  [ClientEvents.COCKTAIL_PURCHASE_ATTEMPT]: {
    foodOrderId: number;
    cocktailOrder: {
      shefId: string;
      subtotal: number;
      lineItems: {
        foodItemId: number;
        quantity: number;
      }[];
    }[];
    totalQuantity: number;
    totalSubtotal: number;
  };

  [ClientEvents.SHEF_APPLICATION_TRACKER_SHOW]: {
    shefFlow: 'dtt' | 'non-dtt';
    numberStepsComplete: number;
    numberStepsTotal: number;
  };

  [ClientEvents.SHEF_APPLICATION_TRACKER_HIDE]: {};

  [ClientEvents.SHEF_APPLICATION_TRACKER_CLICK_STEP]: {
    shefFlow: 'dtt' | 'non-dtt';
    numberStepsComplete: number;
    numberStepsTotal: number;
    stepName: string;
  };

  [ClientEvents.ATTEMPTED_REDIRECT_TO_CHROME_IOS]: {};

  // Description: Button clicked to add item to cart on an upsell menu
  [ClientEvents.UPSELL_CLICK_ADD_TO_CART]: {
    foodItemId: number;
    price: number;
  };

  // Description: Added step upsell closed after adding item to cart
  [ClientEvents.UPSELL_CLICK_CONTINUE]: {};
  // Description: Added step upsell closed with no items added to cart
  [ClientEvents.UPSELL_CLICK_NO_THANKS]: {};

  [ClientEvents.ADD_TO_CALENDAR_CLICK]: { type: string };

  [ClientEvents.FOLLOW_SHEF_START]: {
    location: FollowButtonClickLocation;
    shefId: string;
  };
  [ClientEvents.FOLLOW_SHEF_STOP]: {
    location: FollowButtonClickLocation;
    shefId: string;
  };
  [ClientEvents.SHEF_POST_VIEW]: {
    shefId: string;
    postId: string;
    location: 'email' | 'feed' | 'menu';
  };
  [ClientEvents.VIEW_MENU_REVIEWS_SECTION]: {};
  [ClientEvents.CLICK_REVIEWS_BUTTON]: {
    position: 'bio' | 'section';
  };

  // Description: When user hovers over the food safety certified icon on desktop and clicks on mobile
  [ClientEvents.VIEW_FOOD_SAFETY_POPOVER]: {
    date: string;
    user_id: string;
    shefId: string;
  };

  [ClientEvents.REVIEW_FOOD_ITEM_IMAGE_CLICK]: {
    foodItemSlug: string;
    shefId: string;
    orderRating?: number;
    ratingPercent: number | null | undefined;
  };

  // Description: When the app is going to attempt to request for an app review. This is triggered before validating
  // whether we will actually make the native request.
  [ClientEvents.APP_REVIEW_REQUEST_ATTEMPT]: {
    lastRequestedAtMs: number | undefined;
  };

  // Description: When the request for an app review is skipped and the native request isn't made.
  [ClientEvents.APP_REVIEW_REQUEST_SKIPPED]: {
    reason: 'unavailable' | 'too soon';
    lastRequestedAtMs: number | undefined;
  };

  // Description: When the app has attempted to show the native app review prompt. There's no guarantee that the prompt
  // actually shows. The number of times it can show is limited by Google and Apple.
  [ClientEvents.APP_REVIEW_REQUESTED]: {
    lastRequestedAtMs: number | undefined;
    requestedAtMs: number;
  };

  // Description: When the app has attempted to show the native app review prompt, but an exception was thrown.
  [ClientEvents.APP_REVIEW_REQUEST_FAILED]: {
    lastRequestedAtMs: number | undefined;
    requestedAtMs: number;
  };

  [ClientEvents.WEDGE_PAGE_VIEW]: {
    slug: string;
  };

  [ClientEvents.APP_REDIRECT]: {
    redirectUrl: string;
    utmSource?: string;
    utmMedium: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
  };

  // Description: Push notification opened, bringing user to the app.
  [ClientEvents.PUSH_NOTIFICATION_OPEN]: {
    // If this notification was sent from customer io, it'll have tracking data.
    trackData?: {
      deliveryId: string;
      deliveryToken: string;
    };
  };

  [ClientEvents.PUSH_NOTIFICATION_PROMPT_SHOW]: {
    numDismissed: number;
  };

  [ClientEvents.PUSH_NOTIFICATION_PROMPT_HIDE]: {
    numDismissed: number;
  };

  // Description: When user either grants or denies the push notification permission request
  [ClientEvents.PUSH_NOTIFICATION_PERMISSION_REQUESTED]: {
    status: string;
  };

  // Description: user is navigated to the app listing page
  [ClientEvents.NAVIGATE_TO_APP_LISTING]: Record<string, never>;

  // Description: user dismissed app upsell banner
  [ClientEvents.APP_UPSELL_BANNER_DISMISSED]: Record<string, never>;

  [ClientEvents.DELIVERY_DATE_TOOLTIP_OPEN]: {
    tooltipPage: 'menu' | 'explore';
  };

  [ClientEvents.CHAT_WIDGET_OPEN]: {
    consumerId: string;
    shefId: string;
  };

  [ClientEvents.FLAGGED_CHAT_MODAL_EVENT]: {
    role: string;
    event: string;
    keywordCategory: string | null;
    text: string;
    senderId: string;
  };

  [ClientEvents.TRACKING_PERMISSION_REQUESTED]: {
    status: string;
  };

  [ClientEvents.SINGLE_CART_STARTED_CHECKOUT]: {
    numberOfOrders: number;
  };

  [ClientEvents.MULTI_CART_STARTED_CHECKOUT]: {
    numberOfOrders: number;
    totalCartSubtotal: number;
  };

  [ClientEvents.MULTI_CART_ADD_ORDER_BTN_CLICKED]: {};

  [ClientEvents.MULTI_CART_STARTED_NEW_ORDER]: {
    numberOfOrders: number;
    shefId: string;
  };

  [ClientEvents.DISH_FIRST_CART_CHANGE_DELIVERY_DATE]: {
    reason: 'started-new-order' | 'manual-change' | 'stale';
  };

  [ClientEvents.DISH_FIRST_CLICK_NEXT_DAY_BANNER]: {
    msBeforeMidnight: number;
    nextDayOfWeek: string;
  };

  [ClientEvents.DISH_FIRST_LAZY_LOAD_QUICK_ADD_ITEM]: {
    result: 'success' | 'error';
    userRetryCount: number;
    waitTimeMs: number;
  };

  /**
   * Description: When shef clicks on any of the See Your Reviews buttons on the My Ratings tab
   * categoryName is the order tag
   */
  [ClientEvents.SHEF_DASHBOARD_SEE_YOUR_REVIEWS_BTN_CLICK]: {
    categoryName: string;
  };

  /**
   * Description: When shef clicks on any of the copy link buttons on the Promote Yourself tab
   * linkTo is what the link directs to (i.e. shef menu)
   */
  [ClientEvents.SHEF_DASHBOARD_PROMOTE_YOURSELF_COPY_LINK_CLICK]: {
    linkTo?: string;
  };

  /**
   * Description: When a user advances to the next question on the Personal Shef survey
   * step is the id of the next question
   */
  [ClientEvents.PERSONAL_SHEF_FORM_STEP]: {
    step?: string;
  };

  /**
   * Description: When a user submits a Personal Shef survey
   * responseId is the id of the response from typeform
   */
  [ClientEvents.PERSONAL_SHEF_FORM_SUBMIT]: {
    responseId?: string;
  };

  // Description: When user chooses which source to pull the location (zip) from.
  [ClientEvents.LOCATION_SOURCE_SELECTED]: {
    type: AppLocationSource;
  };
  // Description: When user requests the location permission.
  [ClientEvents.LOCATION_PERMISSION_REQUESTED]: {
    status: string;
  };
  // Description: When user sets the location to use.
  [ClientEvents.LOCATION_SET]: {
    type: AppLocationSource;
  };

  [ClientEvents.CLICK_HELP_WIDGET]: Record<string, never>;

  [ClientEvents.CELEBRITY_EVENT_ERROR]: {
    errors: string[];
  };

  [ClientEvents.REVIEW_GROUP_ORDER_BUTTON_CLICK]: {
    groupOrderId: string;
  };

  [ClientEvents.GROUP_ORDER_RATING_MODAL_SHOW]: {
    groupOrderId: string;
  };

  [ClientEvents.GROUP_ORDER_RATING_MODAL_STEP]: {
    groupOrderId: string;
    step: string;
  };

  [ClientEvents.GROUP_ORDER_RATING_MODAL_PARTIAL_SAVE]: {
    groupOrderId: string;
  };

  [ClientEvents.GROUP_ORDER_RATING_MODAL_SUBMIT]: {
    groupOrderId: string;
  };

  [ClientEvents.GROUP_ORDER_RATING_MODAL_SUBMIT_SUCCESS]: {
    groupOrderId: string;
  };

  [ClientEvents.GROUP_ORDER_RATING_MODAL_SUBMIT_FAILURE]: {
    groupOrderId: string;
    errors: string[];
  };

  [ClientEvents.RATE_RECENT_GROUP_ORDER_MODAL_SHOW]: {
    groupOrderId: string;
  };

  [ClientEvents.RATE_RECENT_GROUP_ORDER_MODAL_CLICK]: {
    groupOrderId: string;
    ratingPercent: number | null;
  };

  [ClientEvents.PERSONAL_SHEF_PLAN_CLICK]: {
    type: string;
  };
  /*
   * in October 2023 we moved browserTrackerId (deviceId) and session_started events to be generated by the shef-web server
   * during the initial request to our servers. In doing this, we may be generating session_started events with a new deviceId
   * for browsers that arent new. In order to fix this, the client checks its local storage for an existing browserTrackerId
   * and if it finds one, sends a reconciliation event, which we use in ETL to fix up the session_started events.
   */
  [ClientEvents.BROWSER_TRACKER_RECONCILIATION]: {
    serverDeviceId: string; // The one set by server which is wrong
    clientDeviceId: string; // The actual one set client side
  };

  [ClientEvents.REFERRAL_REDIRECT]: {
    code: string;
    pattern: string;
    redirectPattern: string;
    redirectUrl: string;
    url: string;
    shefId: string | undefined;
    foodItemSlug: string | undefined;
  };

  [ClientEvents.WAITLIST_ACTION_CLICKED]: {
    type: string;
  };

  [ClientEvents.PRE_CHECKOUT_UPSELL_MODAL_VIEW]: {};

  [ClientEvents.PRE_CHECKOUT_UPSELL_MODAL_CLOSE]: {};

  [ClientEvents.PRE_CHECKOUT_UPSELL_REFRESH_SUGGESTIONS]: {
    page: number;
    hasExhaustedSuggestions: boolean;
  };

  [ClientEvents.PRE_CHECKOUT_UPSELL_ADD_ALL]: {
    // upsell metdata
    upsellFoodItemIds: number[];
    upsellShefIds: string[];
    upsellSubtotal: number;
    // cart metdata
    foodItemIdsInCart: number[];
    numFoodItemsInCart: number;
    shefIdsInCart: string[];
    cartSubtotal: number;
  };

  [ClientEvents.PRE_CHECKOUT_UPSELL_MODAL_VIEW]: {};

  [ClientEvents.PRE_CHECKOUT_UPSELL_MODAL_CLOSE]: {};

  [ClientEvents.PRE_CHECKOUT_UPSELL_REFRESH_SUGGESTIONS]: {
    page: number;
    hasExhaustedSuggestions: boolean;
  };

  [ClientEvents.PRE_CHECKOUT_UPSELL_ADD_ALL]: {
    // upsell metdata
    upsellFoodItemIds: number[];
    upsellShefIds: string[];
    upsellSubtotal: number;
    // cart metdata
    foodItemIdsInCart: number[];
    numFoodItemsInCart: number;
    shefIdsInCart: string[];
    cartSubtotal: number;
  };

  [ClientEvents.FOOD_ITEM_PRICE_RECOMMENDATION]: {
    foodItemId: number;
    shefId: string;
    recommendedPrice: number;
    inPriceRange: boolean;
    price: number;
    servingSize: string;
    dishName: string;
    foodType: string;
    priceChanged: boolean;
    originalPrice?: number;
    newPrice: number;
  };
  [ClientEvents.SHEF_PRICE_OUT_OF_RANGE_MODAL]: {
    skipped: boolean;
  };
  [ClientEvents.SHEF_FRINGE_MODAL_EXPLANATION_VIEW]: {};
  [ClientEvents.SHEF_FRINGE_MODAL_MORE_VIEWED]: {};
  [ClientEvents.SHEF_FRINGE_MODAL_DISMISSED]: {};
  [ClientEvents.SHEF_FRINGE_MODAL_ACCEPTED]: {};
  [ClientEvents.SUBSCRIPTION_CANCEL_MODAL_CONFIRMATION]: {
    mealPlanGroupOrderId?: string | null;
    subscriptionId?: string | null;
    deliveryDateToCancel?: string | null;
  };
  [ClientEvents.SHEF_MESSAGE_CLICK]: {
    recipient: string | null;
  };
}
