import cn from 'classnames';
import { CartChangeLocation } from 'common/events/ClientEvents';
import React from 'react';
import { getTopKCuisines } from 'src/shared/design-system/DishFirstCard/utils';
import { ThumbsUpV2 } from 'src/shared/design-system/Icon';
import { useCartStoreSelectors } from 'src/ztore/cart-store';
import { match } from 'ts-pattern';
import { FoodItemRatings } from '../components/FoodItemRatings';
import { UpsellPriceChip } from '../components/UpsellChips';
import { ServingChip } from './MealPlanDishCard';
import { MealPlanDishImage } from './MealPlanDishImage';
import { MealPlanQuantityPicker } from './MealPlanQuantityPicker';
import { MealPlanFoodItem, MealPlanShefSegment } from './types';

const MEAL_PLAN_DISH_CARD_TITLE_SPLIT_KEYWORD = 'With';

export type MealPlanDishCardUserPreferences = {
  requiredNumber: number;
  servingsPerMeal: number;
};

interface IProps {
  buttonsDisabled: boolean;
  foodItem: MealPlanFoodItem;
  foodItemIndex: number;
  hideServingCount?: boolean;
  isCompleteMeal?: boolean;
  remainingAvailableUnits: number;
  servingsPerMeal: number;
  shefIndex: number;
  shefSegment: MealPlanShefSegment;
  openDishModal: () => void;
  cartChangeLocation: CartChangeLocation;
}

export const MealPlanDishCardLarge: React.FC<IProps> = ({
  buttonsDisabled,
  foodItem,
  foodItemIndex,
  hideServingCount,
  isCompleteMeal = false,
  remainingAvailableUnits,
  servingsPerMeal,
  shefIndex,
  shefSegment,
  openDishModal,
  cartChangeLocation,
}) => {
  const { cart } = useCartStoreSelectors();

  const { id, imageUrl, shef, upsell, cuisineCategories, name, description } = foodItem;
  const unitsInCart = cart[shef.id]?.cartItems.find((item) => item.foodItem.id === foodItem.id)?.quantity ?? 0;

  const upsellPriceChip = <UpsellPriceChip upsell={upsell} servingsPerMeal={servingsPerMeal} />;

  const [titleText, descriptionText] = match(isCompleteMeal)
    .with(true, () => {
      const SPLIT_REGEX = /with/i;
      const splitWith = name?.split(SPLIT_REGEX, 2);
      if (splitWith && splitWith.length > 1) {
        const title = splitWith[0];
        const parsedDescription = splitWith.slice(1).join(` ${MEAL_PLAN_DISH_CARD_TITLE_SPLIT_KEYWORD} `);

        return [title, `${MEAL_PLAN_DISH_CARD_TITLE_SPLIT_KEYWORD} ${parsedDescription}`];
      }
      return [name, description];
    })
    .otherwise(() => [name, description]);

  const cuisines = getTopKCuisines({ cuisineCategories, k: 2 });

  return (
    <div
      onClick={openDishModal}
      key={id}
      className={cn(
        'mb-4 flex min-w-[300px] max-w-[308px] cursor-pointer flex-col items-center justify-start self-start',
        'overflow-hidden rounded-md border border-neutral-100 bg-white p-0 text-left shadow-lg md:min-w-[308px] '
      )}>
      <MealPlanDishImage
        imageUrl={imageUrl ?? ''}
        remainingAvailableUnits={remainingAvailableUnits}
        unitsInCart={unitsInCart}
        upsell={upsell}
      />
      <div className={`flex size-full grow flex-col justify-between p-4`}>
        <div className='flex h-full flex-col items-start justify-start'>
          {cuisines && <p className='mb-1 line-clamp-1 text-caption-400 text-eggplant-500'>{cuisines}</p>}
          <p className={cn('mb-1 line-clamp-1 text-h5-bold text-eggplant', { 'mb-2': !isCompleteMeal })}>{titleText}</p>
          {isCompleteMeal && <p className='mb-1 line-clamp-1 text-caption-400 text-eggplant-500'>{descriptionText}</p>}
        </div>
        <div className='flex items-center'>
          <div className='self-center font-semibold'>
            <ThumbsUpV2 outline height={12} width={12} />
          </div>
          <FoodItemRatings
            ratingCount={foodItem.ratingCount}
            averageRating={foodItem.averageRating}
            foodItemId={foodItem.id}
          />
        </div>
        <div className='w-full'>
          <div className={'flex w-full grow flex-row justify-between pt-4 align-baseline'}>
            <div className='flex items-center gap-2'>
              {!hideServingCount && <ServingChip numServings={servingsPerMeal} />}
              {upsellPriceChip}
            </div>
            <div className='flex justify-end'>
              <MealPlanQuantityPicker
                foodItem={foodItem}
                value={unitsInCart}
                disabled={buttonsDisabled}
                shefSegment={shefSegment}
                shefPosition={shefIndex + 1}
                foodItemPosition={foodItemIndex + 1}
                cartChangeLocation={cartChangeLocation}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
