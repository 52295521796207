import { gt, lte } from 'common/PatternMatchingUtilities';
import { match } from 'ts-pattern';
import Chip, { ChipColor } from 'web-common/src/shared/design-system/components/Chip';

interface FoodItemChipProps {
  remainingAvailableUnits: number;
  unitsInCart: number;
}

export const FoodItemChip: React.FC<FoodItemChipProps> = ({ remainingAvailableUnits, unitsInCart }) => {
  const lowAvailabilityChip = <Chip color={ChipColor.NEUTRAL_50} copy={`${remainingAvailableUnits} left`} />;
  const soldOutChip = <Chip color={ChipColor.RASPBERRY} copy='Sold Out' />;
  const maxQuantityChip = <Chip color={ChipColor.RASPBERRY} copy='Max Quantity' />;

  return match({ remainingAvailableUnits, unitsInCart })
    .with({ unitsInCart: gt(0), remainingAvailableUnits: lte(0) }, () => maxQuantityChip)
    .with({ unitsInCart: lte(0), remainingAvailableUnits: lte(0) }, () => soldOutChip)
    .with({ remainingAvailableUnits: lte(5) }, () => lowAvailabilityChip)
    .otherwise(() => null);
};
