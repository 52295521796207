import cn from 'classnames';
import { CartChangeLocation, ClientEvents } from 'common/events/ClientEvents';
import { isMealPlanSideDish } from 'common/MealPlanUtils';
import { isNull, noop } from 'lodash';
import React, { useContext } from 'react';
import { MealPlanDishCardFoodItemFragment } from 'src/gqlReactTypings.generated.d';
import { MinusV2, PlusV2 } from 'src/shared/design-system/Icon';
import { useLocation } from 'src/shared/hooks/useLocation';
import { useTracker } from 'src/shared/hooks/useTracker';
import { Routes } from 'src/shared/Routes';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';
import { useCartStoreSelectors } from 'src/ztore/cart-store';
import { MealPlanSubscriptionContext } from '../MealPlanSubscriptionsContext';
import { MealPlanFoodItem, MealPlanShefSegment } from './types';

interface MealPlanQuantityPickerProps {
  value: number;
  shefSegment?: MealPlanShefSegment;
  foodItem: MealPlanFoodItem | MealPlanDishCardFoodItemFragment;
  disabled?: boolean;
  shefPosition?: number;
  foodItemPosition?: number;
  editingDisabled?: boolean;
  outline?: boolean;
  onIncrement?: (foodItem) => void;
  cartChangeLocation: CartChangeLocation | null;
}

const largerTapTargetClasses =
  'before:content-[""] before:absolute before:w-[32px] before:h-[32px] before:cursor-pointer before:left-[-8px] relative';
export const MealPlanQuantityPicker: React.FC<MealPlanQuantityPickerProps> = ({
  value = 0,
  foodItem,
  disabled = false,
  shefSegment,
  shefPosition,
  foodItemPosition,
  editingDisabled = false,
  outline = true,
  onIncrement,
  cartChangeLocation,
}) => {
  const tracker = useTracker();
  const location = useLocation();
  const isEditPage = locationInRoutes(location, [Routes.CONSUMER_MEAL_PLANS_EDIT]);
  const { mealPlanType } = useContext(MealPlanSubscriptionContext);
  const { increaseCartQuantity, decreaseCartQuantity } = useCartStoreSelectors();

  const incrementInCart = (foodItem, shefId, shefPosition, foodItemPosition, shefSegment) => {
    if (editingDisabled || isNull(cartChangeLocation)) {
      return;
    }
    // Cart manipulation
    const cartItem = {
      foodItem,
      quantity: 1,
    };
    increaseCartQuantity(cartItem, shefSegment);

    // Analytics
    const foodItemId = foodItem.id;
    const isSideFoodItem = isMealPlanSideDish(foodItemId);
    if (isSideFoodItem) {
      tracker.track(ClientEvents.MEAL_PLANNING_MVP_SIDE_OR_STAPLE_ADD, {
        item: foodItemId,
        path: isEditPage ? 'edit' : 'checkout',
        location: cartChangeLocation,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- format is a string
        format: (mealPlanType?.format as string) ?? '',
      });
    } else {
      tracker.track(ClientEvents.MEAL_PLANNING_MVP_ADD_TO_CART, {
        foodItemId,
        shefId,
        shefPosition,
        foodItemPosition,
        path: isEditPage ? 'edit' : 'checkout',
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- format is a string
        format: (mealPlanType?.format as string) ?? '',
        location: cartChangeLocation,
      });
    }
  };

  const increment = (foodItem, shefId, shefPosition, foodItemPosition, shefSegment) => {
    if (editingDisabled) return noop;

    if (onIncrement) {
      onIncrement(foodItem);
    }

    return incrementInCart(foodItem, shefId, shefPosition, foodItemPosition, shefSegment);
  };

  const decrementInCart = (foodItem, shefId, shefPosition, foodItemPosition) => {
    if (editingDisabled || isNull(cartChangeLocation)) {
      return;
    }
    const cartItem = {
      foodItem,
      quantity: 1,
    };
    decreaseCartQuantity(cartItem);

    tracker.track(ClientEvents.MEAL_PLANNING_MVP_REMOVE_FROM_CART, {
      foodItemId: foodItem.id,
      shefId,
      shefPosition,
      foodItemPosition,
      path: isEditPage ? 'edit' : 'checkout',
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- format is a string
      format: (mealPlanType?.format as string) ?? '',
      location: cartChangeLocation,
    });
  };

  const decrement = editingDisabled ? noop : decrementInCart;

  return (
    <div
      className={cn(`flex flex-row items-center justify-between rounded-lg border border-solid`, {
        'border-none': !outline,
        'gap-2 border-neutral-100 bg-raspberry px-2 text-white': value >= 1 && !editingDisabled,
        'border-white': value < 1 && !editingDisabled,
        'bg-raspberry': editingDisabled,
        'text-white': editingDisabled,
      })}>
      {value >= 1 && !editingDisabled && (
        <button
          className={cn(
            'm-0 flex items-center border-none p-0 font-bold leading-tight text-white',
            largerTapTargetClasses,
            {
              'bg-white text-raspberry': value < 1,
              'bg-raspberry text-white': value >= 1,
            }
          )}
          onClick={(e) => {
            e.stopPropagation();
            decrement(foodItem, foodItem.shef.id, shefPosition, foodItemPosition);
          }}>
          <MinusV2 />
        </button>
      )}
      {(value >= 1 || editingDisabled) && (
        <span className={cn('mt-1 text-base', { 'px-2': editingDisabled })}>{value}</span>
      )}
      {!editingDisabled && (
        <button
          disabled={disabled}
          className={cn('m-0 flex items-center border-none p-0 font-bold leading-tight', largerTapTargetClasses, {
            ' bg-white text-neutral-200': disabled && value < 1,
            'bg-raspberry text-raspberry-hover': disabled && value >= 1,
            'bg-white text-raspberry': value < 1 && !disabled,
            'bg-raspberry text-white': value >= 1 && !disabled,
          })}
          onClick={(e) => {
            e.stopPropagation();
            increment(foodItem, foodItem.shef.id, shefPosition, foodItemPosition, shefSegment);
          }}>
          <PlusV2 />
        </button>
      )}
    </div>
  );
};
