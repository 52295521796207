import { noOp } from 'common/Constants';
import { isEqual, isNil } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { DishModalFoodItemFragment, MealPlanFormat, Upsell, useDishModalQuery } from 'src/gqlReactTypings.generated.d';
import { CardType } from 'src/pages/consumer/meal-plans/checkout/MealPlanDishCard';
import { MealPlanFoodItem, MealPlanShefSegment } from 'src/pages/consumer/meal-plans/checkout/types';
import { useCartStoreSelectors } from 'src/ztore/cart-store';
import { BaseDishModal, IBaseDishModalFormState } from './BaseDishModal';
import { DEFAULT_CUSTOMIZATION_ID } from './types';

interface IMealPlanDishModal {
  disabled?: boolean;
  foodItem: MealPlanFoodItem;
  isShowing?: boolean;
  loadedData?: DishModalFoodItemFragment;
  maxQuantity?: number | null;
  shareUrl?: string;
  shefMenuUrl?: string;
  shefSegment: MealPlanShefSegment;
  showAddButton?: boolean;
  showModalPrompt?: boolean;
  onClose?: () => void;
  onQuantityChange?: (quantity: number) => void;
  openModal?: () => void;
  upsell?: Upsell | null;
  servingsPerMeal?: number;
  cardType: CardType;
  format: MealPlanFormat;
}

const MealPlanDishModal: React.FC<IMealPlanDishModal> = (props) => {
  const { cart, setItemQuantity } = useCartStoreSelectors();

  const {
    foodItem,
    servingsPerMeal,
    shefSegment,
    upsell,
    onClose: handleClose = noOp,
    onQuantityChange,
    cardType,
    format,
  } = props;

  const unitsInCart = cart[foodItem.shef.id]?.cartItems.find((item) => item.foodItem.id === foodItem.id)?.quantity ?? 0;
  const minQuantityForModal = unitsInCart > 0 ? 0 : 1;

  const [formState, setFormState] = useState<IBaseDishModalFormState>({
    spiceLevelCustomizationId: DEFAULT_CUSTOMIZATION_ID,
    quantity: unitsInCart === 0 ? 1 : unitsInCart,
  });

  const formInitialState: IBaseDishModalFormState = useMemo(
    () => ({
      spiceLevelCustomizationId: DEFAULT_CUSTOMIZATION_ID,
      quantity: unitsInCart === 0 ? 1 : unitsInCart,
    }),
    [unitsInCart]
  );

  const submit = useCallback(() => {
    const cartItem = {
      foodItem,
      quantity: formState.quantity,
    };
    setItemQuantity(cartItem, shefSegment);
    onQuantityChange?.(formState.quantity);
    handleClose();
  }, [setItemQuantity, foodItem.id, foodItem.shef.id, formState.quantity, handleClose]);

  return (
    <>
      <BaseDishModal
        {...props}
        foodItem={foodItem}
        canSubmitCart={!isEqual(formState, formInitialState)}
        submit={submit}
        minQuantity={minQuantityForModal}
        productType={'subscriptions'}
        isEditingCart={unitsInCart > 0}
        unitsInCart={unitsInCart}
        formState={formState}
        upsell={upsell}
        showPortionSizeControls={false}
        setFormState={setFormState}
        cardType={cardType}
        format={format}
        servingsPerMeal={servingsPerMeal}></BaseDishModal>
    </>
  );
};

const WrapperMealPlanDishModal: React.FC<IMealPlanDishModal> = (props) => {
  const { data: foodItemData, loading } = useDishModalQuery({
    variables: { foodItemId: props.foodItem.id },
    skip: isNil(props.foodItem.shef.id),
  });

  if (loading) {
    return null;
  }

  return <MealPlanDishModal {...props} loadedData={foodItemData?.foodItem} />;
};

export default WrapperMealPlanDishModal;
