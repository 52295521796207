import { ratingCountHash, ratingValueHash } from 'common/RatingUtilities';

interface IProps {
  ratingCount: number;
  averageRating: number;
  foodItemId: number;
}

export const FoodItemRatings: React.FC<IProps> = ({ ratingCount, averageRating, foodItemId }) => (
  <>
    <p className='ml-1 text-caption-600 font-bold text-eggplant'>
      {ratingCount > 5 ? averageRating : ratingValueHash(foodItemId)}%
    </p>
    <p className='ml-1 text-label-400 text-eggplant-300'>
      ({ratingCount > 5 ? ratingCount : ratingCountHash(foodItemId)})
    </p>
  </>
);
