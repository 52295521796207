import { isFacebookBrowser, isInstagramBrowser } from 'common/BrowserUtilities';
import { noOp } from 'common/Constants';
import { ShefErrorCode } from 'common/errors/ShefErrors';
import { ClientEvents } from 'common/events/ClientEvents';
import { StorageKeys } from 'common/storage/constants';
import { GraphQLError } from 'graphql';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { OAuthLoginType, OAuthMissingFieldsResult } from 'src/gqlReactTypings.generated.d';
import { Google } from 'src/shared/design-system/Icon/Google';
import { LoginButtonV2 } from 'src/shared/design-system/Login/LoginButtonV2';
import { useLocation } from 'src/shared/hooks/useLocation';
import { useOnMount } from 'src/shared/hooks/useOnMount';
import { useTracker } from 'src/shared/hooks/useTracker';
import { getBrowserStorage } from 'src/shared/storage/Storage';
import { v4 as uuidv4 } from 'uuid';
import { decodeOAuthState, getEncodedOAuthState, isOAuthStateMatching } from './oAuthState';
import { useLoginOrSignUpWithOAuth } from './useLoginOrSignUpWithOAuth';

// Ughh. Google deprecated the old JS APIs and won't let us use a custom button with their new API.
// Have to use OAuth endpoint directly.
const GOOGLE_OAUTH_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';
const POPUP_WIDTH_PX = 500;
const POPUP_HEIGHT_PX = 600;

const POLL_INTERVAL_MS = 250;
const POLL_TIMEOUT_MS = 10 * 60 * 1000; // 10 minutes

const storage = getBrowserStorage();
interface IGoogleLoginV3Props {
  onSuccess?: (isExistingUser: boolean) => void;
  onError: (error: Error) => void;
  onAttempt?: () => void;
  onMissingFields: (type: OAuthLoginType, token: string, missingFieldsResult: OAuthMissingFieldsResult) => void;
  requireZipCode?: boolean;
  signUpEntryPoint?: string | null;
}

type PopupPollState = { shouldPoll: false } | { shouldPoll: true; popup: Window | null };

/**
 * Check for if we know Google SSO does not support the browser.
 * See: https://developers.googleblog.com/2020/08/guidance-for-our-effort-to-block-less-secure-browser-and-apps.html
 */
const isUnsupportedBrowser = (): boolean =>
  isFacebookBrowser(navigator.userAgent) || isInstagramBrowser(navigator.userAgent);

/**
 * This works by opening a popup with the Google OAuth dialog. When the customer logs in with Google in the popup,
 * Google redirects within the popup to our login page with the access token as a parameter in the location hash.
 *
 * When this happens, we read the access token and put it in local storage. The original window will poll for the
 * access token in local storage, and when it finds it, will log in.
 *
 * This is similar to the flow of how Google shows here:
 * https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow
 */
export const GoogleLoginV3: React.FC<IGoogleLoginV3Props> = ({
  onSuccess,
  onError,
  onAttempt,
  onMissingFields,
  requireZipCode,
  signUpEntryPoint,
}) => {
  const tracker = useTracker();
  if (isNil(process.env.REACT_APP_GOOGLE_CLIENT_ID)) {
    throw new Error('Missing env variable: REACT_APP_GOOGLE_CLIENT_ID');
  }
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const redirectUrlBase = process.env.REACT_APP_FRONT_END_URL;
  if (isNil(redirectUrlBase)) {
    throw new Error('Missing env variable: REACT_APP_FRONT_END_URL');
  }

  const { login } = useLoginOrSignUpWithOAuth({
    type: OAuthLoginType.GoogleWeb,
    onError,
    onSuccess,
    onMissingFields,
    requireZipCode,
    signUpEntryPoint,
  });
  const location = useLocation();
  const [popupPollState, setPopupPollState] = useState<PopupPollState>({ shouldPoll: false });
  const uuid = useRef<string>(uuidv4());

  useOnMount(() => {
    storage.removeItem(StorageKeys.GOOGLE_OAUTH_PARAMS);

    if (!location.hash) {
      return;
    }

    const fragmentString = location.hash.substring(1);
    const params = new URLSearchParams(fragmentString);

    const encodedState = params.get('state');
    const state = decodeOAuthState(encodedState);
    if (state?.loginType !== OAuthLoginType.GoogleWeb) {
      return;
    }

    const accessToken = params.get('access_token') ?? undefined;
    if (accessToken) {
      storage.setItem(StorageKeys.GOOGLE_OAUTH_PARAMS, JSON.stringify({ accessToken, state }));
      window.close();
    }
  });

  const checkForOAuthComplete = useCallback(
    (popup: Window | null) => {
      const params = storage.getItem(StorageKeys.GOOGLE_OAUTH_PARAMS);
      if (isNil(params)) {
        if (popup?.closed) {
          setPopupPollState({ shouldPoll: false });
          onError(new Error('Window closed for Google login'));
        }
        return;
      }

      const parsedParams = JSON.parse(params);
      if (
        !isNil(parsedParams.state) &&
        !isNil(parsedParams.accessToken) &&
        isOAuthStateMatching(parsedParams.state, OAuthLoginType.GoogleWeb, uuid.current)
      ) {
        storage.removeItem(StorageKeys.GOOGLE_OAUTH_PARAMS);

        setPopupPollState({ shouldPoll: false });
        login(parsedParams.accessToken).catch(console.error);
      }
    },
    [setPopupPollState, login, onError]
  );

  useEffect(() => {
    if (popupPollState.shouldPoll) {
      const poll = setInterval(() => checkForOAuthComplete(popupPollState.popup), POLL_INTERVAL_MS);
      const timeout = setTimeout(() => {
        setPopupPollState({ shouldPoll: false });
        if (!window.closed) {
          onError(new Error('Google login timed out'));
        }
      }, POLL_TIMEOUT_MS);

      return () => {
        clearInterval(poll);
        clearTimeout(timeout);
      };
    }
    return noOp;
  }, [popupPollState, checkForOAuthComplete, onError]);

  const onClick = () => {
    tracker.track(ClientEvents.SSO_SIGNUP_CLICKED, { sso_name: 'google_v3' });
    if (isUnsupportedBrowser()) {
      onError(
        new GraphQLError(
          'Google login is not supported in this app. Please try opening this page in your default browser.',
          { extensions: { code: ShefErrorCode.GOOGLE_BROWSER_NOT_SUPPORTED } }
        )
      );
      return;
    }

    storage.removeItem(StorageKeys.GOOGLE_OAUTH_PARAMS);

    onAttempt?.();

    const params = {
      client_id: googleClientId,
      redirect_uri: new URL('/login', redirectUrlBase).toString(),
      scope: 'profile email',
      response_type: 'token',
      state: getEncodedOAuthState(OAuthLoginType.GoogleWeb, uuid.current),
    };

    const url = new URL(GOOGLE_OAUTH_ENDPOINT);
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    // center the popup
    const popupX = !isNil(window.top) ? window.top.outerWidth / 2 + window.top.screenX - POPUP_WIDTH_PX / 2 : 0;
    const popupY = !isNil(window.top) ? window.top.outerHeight / 2 + window.top.screenY - POPUP_HEIGHT_PX / 2 : 0;
    const options = `
      toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      left=${popupX},
      top=${popupY},
      width=${POPUP_WIDTH_PX}px,
      height=${POPUP_HEIGHT_PX}px`;
    const popup = window.open(url.toString(), 'GoogleLogin', options);
    setPopupPollState({ shouldPoll: true, popup });
  };

  return (
    <LoginButtonV2 icon={<Google />} onClick={onClick}>
      Continue with Google
    </LoginButtonV2>
  );
};
