import { Slugs } from 'common/urls/Slugs';
import React from 'react';
import { MEAL_PLANS_PREFERENCES_FIRST_STEP } from 'src/pages/consumer/meal-plans/complete-meal-intake/preferences/consts';
import { useMealPlanSurfacingLogic } from 'src/pages/consumer/meal-plans/hooks/useMealPlanSurfacingLogic';
import { Shef } from 'src/shared/design-system/Icon/Shef';
import { TrackedNavLink } from 'src/shared/design-system/TrackedNavLink';
import { Routes } from 'src/shared/Routes';
import { getSluggedPath } from 'src/shared/utils/RouteUtilities';
import { useCachedZipCode } from 'src/user-preferences/useCachedZipCode';
import styled from 'styled-components';
import { Colors } from 'web-common/src/shared/styles';

export type LogoProps = {
  color?: string;
  height?: string;
};

const StyledNavLink = styled(TrackedNavLink)<{ color: string }>`
  color: ${({ color }) => color} !important;
  display: flex;
  align-items: center;
`;

const NoLinkWrapper = styled.div<{ color: string }>`
  color: ${({ color }) => color} !important;
  display: flex;
  align-items: center;
`;

export const ShefLogo: React.FC<LogoProps> = ({ height = '24px', color = Colors.RADISH }) => {
  const { shefLogoDisabled, mealPlanUrl, setMealPlanHasBeenRedirected } = useMealPlanSurfacingLogic();
  const [zipCode] = useCachedZipCode();
  const onClick = () => {
    if (mealPlanUrl?.includes(MEAL_PLANS_PREFERENCES_FIRST_STEP)) {
      setMealPlanHasBeenRedirected();
    }
  };

  return shefLogoDisabled ? (
    <NoLinkWrapper color={color}>
      <Shef height={height} width='100%' />
    </NoLinkWrapper>
  ) : (
    <StyledNavLink
      onClick={onClick}
      to={
        mealPlanUrl ??
        (zipCode == null
          ? Routes.CONSUMER_LANDING
          : getSluggedPath(Routes.CONSUMER_EXPLORE, [{ slug: Slugs.ZIP_CODE, value: zipCode ?? '' }]))
      }
      name='shef-logo'
      color={color}>
      <Shef height={height} width='100%' />
    </StyledNavLink>
  );
};
