import { Upsell } from 'src/gqlReactTypings.generated.d';
import { DynamicImage } from 'src/shared/design-system/DynamicImage';
import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';
import { UpsellLabelChip } from '../components/UpsellChips';
import { FoodItemChip } from './FoodItemChip';

const LARGE_IMAGE_WIDTH = 308;
const SMALL_IMAGE_WIDTH = 300;
const IMAGE_HEIGHT = 200;

interface MealPlanDishImageProps {
  imageUrl: string;
  remainingAvailableUnits: number;
  unitsInCart: number;
  upsell?: Upsell | null;
}

export const MealPlanDishImage: React.FC<MealPlanDishImageProps> = ({
  imageUrl,
  remainingAvailableUnits,
  unitsInCart,
  upsell,
}) => {
  const isInCart = unitsInCart > 0;
  const { isSmallScreen } = useScreenContext();
  const width = isSmallScreen ? SMALL_IMAGE_WIDTH : LARGE_IMAGE_WIDTH;
  const disabled = !isInCart && remainingAvailableUnits <= 0;
  const renderGreyOverlay = <div className={`absolute size-full bg-white opacity-60`} />;

  const upsellChip = (
    <div className='absolute bottom-4 left-2'>
      <UpsellLabelChip upsell={upsell} />
    </div>
  );
  const foodItemChip = (
    <div className='absolute left-2 top-2'>
      <FoodItemChip remainingAvailableUnits={remainingAvailableUnits} unitsInCart={unitsInCart} />
    </div>
  );

  const imageContent = (
    <div className='relative'>
      {disabled && renderGreyOverlay}
      <DynamicImage
        className='shrink-0'
        src={imageUrl}
        width={width}
        height={IMAGE_HEIGHT}
        sourceWidth={width}
        sourceHeight={IMAGE_HEIGHT}
        style={{ maxWidth: `${width}px` }} // override Image max-width style
      />
      <>
        {foodItemChip}
        {upsell && upsellChip}
      </>
    </div>
  );

  return imageContent;
};
