import { lt } from 'common/PatternMatchingUtilities';
import {
  NEWBIE_PROMO_CODE_FIRST_30_FREE_DELIVERY,
  NEWBIE_PROMO_CODE_FIRST_50_FREE_DELIVERY,
} from 'common/PromoCodeUtilities';
import { formatNumOrdersReferralBanner } from 'common/ReferTextUtilities';
import { useCallback } from 'react';
import { PromoCodeType } from 'src/gqlReactTypings.generated.d';
import { useLocation } from 'src/shared/hooks/useLocation';
import { ALL_WAITLIST_ROUTES, Routes } from 'src/shared/Routes';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';
import { match } from 'ts-pattern';
import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';
import { CloseButton } from './components/DishFirstHeader/styles/modal';
import { useShowConsumerHeaderPersistentBanner } from './components/header-v2/useShowConsumerHeaderPersistentBanner';
import { useMealPlanDiscount } from './meal-plans/useMealPlanDiscount';

export const ConsumerBanner: React.FC = () => {
  const { enabled, handlePromoClose, promoCode } = useShowConsumerHeaderPersistentBanner();
  const { isSmallScreen } = useScreenContext();

  const location = useLocation();
  const hideBanner = locationInRoutes(location, Object.values(ALL_WAITLIST_ROUTES));
  const { numOrdersText, referralPromo: eligibleMealPlanReferralPromo, discountText } = useMealPlanDiscount();

  const isOnMealPlanIntake = locationInRoutes(location, [
    Routes.CONSUMER_LANDING,
    Routes.CONSUMER_MEAL_PLANS_CHECKOUT,
    Routes.CONSUMER_MEAL_PLANS,
    Routes.CONSUMER_MEAL_PLANS_PREFERENCES,
    Routes.CONSUMER_MEAL_PLANS_CHECKOUT,
  ]);

  const onBannerClose = useCallback(handlePromoClose, [handlePromoClose]);

  const referralPromoUsesLeft = eligibleMealPlanReferralPromo?.usesRemainingForUser ?? 0;
  const valueText = <span className='font-juniper-bay text-h5'>{discountText}</span>;
  const bannerMessage = match({
    firstPromoOnly: promoCode?.firstPromoOnly,
    isFreeDelivery: promoCode?.isFreeDelivery,
    isOnMealPlanIntake,
    promoCode: promoCode?.code,
    promoCodeType: promoCode?.promoCodeType,
    referralPromoUsesLeft,
    isPercentage: promoCode?.isPercentage,
  })
    .with({ isOnMealPlanIntake: true, referralPromoUsesLeft: lt(2) }, () => (
      <span>
        Get {valueText} off your {numOrdersText}!
      </span>
    ))
    .with(
      { promoCode: NEWBIE_PROMO_CODE_FIRST_30_FREE_DELIVERY },
      () => 'You get $30 off your first 5 orders - $10 off your first order plus free delivery!'
    )
    .with(
      { promoCode: NEWBIE_PROMO_CODE_FIRST_50_FREE_DELIVERY },
      () => 'You get $50 off your first 5 orders - $10 off your first order plus free delivery!'
    )
    .with({ isFreeDelivery: true }, () => 'Yay! You get free delivery on your next order!')
    .with({ promoCodeType: PromoCodeType.Referral }, () => (
      <span>
        You just scored {valueText} off {formatNumOrdersReferralBanner(promoCode?.usesRemainingForUser ?? 0)}
      </span>
    ))
    .with({ firstPromoOnly: true, isPercentage: false }, () => (
      <span>You just scored {valueText} off your first order!</span>
    ))
    .with({ firstPromoOnly: true, isPercentage: true }, () => (
      <span>You just scored {valueText} off your first order!</span>
    ))
    .otherwise(() => {
      const orderText = formatNumOrdersReferralBanner(promoCode?.usesRemainingForUser ?? 0);
      return (
        <span>
          Yay! You get {valueText} off{orderText && ' '}
          {orderText}!
        </span>
      );
    });

  const promoBannerHidden = !enabled || hideBanner;

  if (promoBannerHidden) return null;

  return (
    <div
      id='consumer-banner'
      className='z-banner flex w-full items-center justify-between bg-mint-50 p-2 font-sans text-caption-700-caps uppercase text-mint-600'>
      <div className={`flex w-full items-start justify-center gap-1 font-semibold`}>
        <div className='flex flex-col items-start justify-center gap-0 sm:flex-row sm:items-center sm:gap-1'>
          <span className=''>{bannerMessage}</span>
          {!isSmallScreen && <span className='mt-1 text-caption-400 italic'>Discount applied at checkout</span>}
        </div>
      </div>
      <div className='cursor-pointer'>
        <CloseButton onClick={onBannerClose} />
      </div>
    </div>
  );
};
