export const USER_SELECTED_ALL_DISHES_NOTIFICATION_TEXT = `You have selected all your dishes for your this order!`;
export const USER_SELECTED_ALL_SIDES_NOTIFICATION_TEXT = `You have selected all your sides for your this order!`;

export enum MealPlanEntryPoints {
  MPLandingPage = 'MPLandingPage',
  ExploreBanner = 'ExploreBanner',
  ExploreModal = 'ExploreModal',
  HamburgerMenu = 'HamburgerMenu',
  CancelAndResubscribe = 'CancelAndResubscribe',
}

export enum MealPlanPreferencesRedirectSource {
  TasteProfileFilterModal = 'TasteProfileFilterModal',
  CuisinePreferencesModal = 'CuisinePreferencesModal',
}
