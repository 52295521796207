import { COMPLETE_MEAL_REGION_IDS, FLEXIBLE_SIDES_REGION_IDS, MEAL_PLAN_REGIONS_IDS } from 'common/Constants';
import { SessionStorageKeys, StorageKeys } from 'common/storage/constants';
import { isNil } from 'lodash';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { useFeatureFlag } from 'src/shared/design-system/FeatureFlag';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { Routes } from 'src/shared/Routes';
import { useLocalStorage, useSessionStorage } from 'src/shared/storage/Storage';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';
import { useCachedZipCode } from 'src/user-preferences/useCachedZipCode';
import { match, P } from 'ts-pattern';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import {
  COMPLETE_MEAL_PLANS_PREFERENCES_FIRST_STEP,
  FLEXIBLE_SIDES_MEAL_PLANS_PREFERENCES_FIRST_STEP,
  MEAL_PLANS_PREFERENCES_FIRST_STEP_MAINS_PLUS_SIDES,
} from '../complete-meal-intake/preferences/consts';
import { MealPlanEntryPoints } from '../consts';
import { MealPlanSubscriptionContext } from '../MealPlanSubscriptionsContext';

interface MealPlanSurfacingLogicReturn {
  mealPlanUrl?: string;
  mealPlanIntakeRouteByRegion?: string;
  shefLogoDisabled: boolean;
  shouldShowMealPlanBanner: boolean;
  shouldShowMealPlanButton: boolean;
  shouldShowMealPlanModal: boolean;
  mealPlanHasBeenRedirected: boolean;
  setHasSeenMealPlanModal: () => void;
  setMealPlanHasBeenRedirected: () => void;
}

export const useMealPlanSurfacingLogic = (): MealPlanSurfacingLogicReturn => {
  const [currentUser, currentUserLoading] = useCurrentUser();
  const { userHasActiveMealPlan } = useContext(MealPlanSubscriptionContext);
  const completeMealPlansEnabled = useFeatureFlag('completeMealPlansEnabled');
  const flexibleSidesEnabled = useFeatureFlag('flexibleSidesEnabled');
  const { location } = useHistory();
  const userIsLoggedIn = !isNil(currentUser) && !currentUserLoading;
  const [, , zipCodeMetaData] = useCachedZipCode();
  const regionId = zipCodeMetaData?.cachedZipCodeData?.zipCode?.region?.id;
  const isInMealPlanRegion = MEAL_PLAN_REGIONS_IDS.includes(regionId ?? 0);
  const isInCompleteMealPlanRegion = completeMealPlansEnabled && COMPLETE_MEAL_REGION_IDS.includes(regionId ?? 0);
  const isInFlexibleSidesRegion = flexibleSidesEnabled && FLEXIBLE_SIDES_REGION_IDS.includes(regionId ?? 0);

  const isInIntakeFlow = locationInRoutes(location, [
    Routes.CONSUMER_MEAL_PLANS_PREFERENCES,
    Routes.CONSUMER_MEAL_PLANS_CHECKOUT,
  ]);

  const [{ entryPoint }] = useQueryParams({
    entryPoint: withDefault(StringParam, undefined),
  });

  const [visitedMealPlans] = useLocalStorage<boolean>(StorageKeys.VISITED_MEAL_PLANS, false);
  const [visitedExplorePage] = useLocalStorage<boolean>(StorageKeys.VISITED_EXPLORE_PAGE, false);
  const [hasSeenMealPlanModalLifetime, setHasSeenMealPlanModalLifetime] = useLocalStorage<boolean>(
    StorageKeys.USER_HAS_SEEN_EXPLORE_MEAL_PLAN_PROPOSITION_MODAL,
    false
  );
  const [hasSeenMealPlanModalSession, setHasSeenMealPlanModalSession] = useSessionStorage<boolean>(
    SessionStorageKeys.USER_HAS_SEEN_EXPLORE_MEAL_PLAN_PROPOSITION_MODAL,
    false
  );
  const [mealPlanHasBeenRedirected, setMealPlanHasBeenRedirectedSession] = useSessionStorage<boolean>(
    SessionStorageKeys.MEAL_PLAN_HAS_BEEN_REDIRECTED,
    false
  );

  const setHasSeenMealPlanModal = () => {
    setHasSeenMealPlanModalLifetime(true);
    setHasSeenMealPlanModalSession(true);
  };

  const setMealPlanHasBeenRedirected = () => {
    setMealPlanHasBeenRedirectedSession(true);
  };

  /**
   * Determine which version of intake/plan type option the user should see.
   * Based on region.
   * TODO refactor: https://app.asana.com/0/1206267086554345/1209009538932059
   */
  const mealPlanIntakeRouteByRegion: string | undefined = match({
    isInCompleteMealPlanRegion,
    isInFlexibleSidesRegion,
    isInMealPlanRegion,
  })
    .with({ isInFlexibleSidesRegion: true }, () => FLEXIBLE_SIDES_MEAL_PLANS_PREFERENCES_FIRST_STEP)
    .with({ isInCompleteMealPlanRegion: true }, () => COMPLETE_MEAL_PLANS_PREFERENCES_FIRST_STEP)
    .with({ isInMealPlanRegion: true }, () => MEAL_PLANS_PREFERENCES_FIRST_STEP_MAINS_PLUS_SIDES)
    .otherwise(() => undefined);

  /**
   * Determine where we should redirect a user from shef.com
   * And also where the Shef logo should take the user
   * TODO refactor: https://app.asana.com/0/1206267086554345/1209009538932059
   */
  const mealPlanUrl: string | undefined = match({
    userHasActiveMealPlan,
    visitedExplorePage,
    isInMealPlanRegion,
    mealPlanHasBeenRedirected,
  })
    .with({ userHasActiveMealPlan: true }, () => Routes.CONSUMER_MEAL_PLANS_UPCOMING_ORDER)
    .with(
      {
        visitedExplorePage: false,
        isInMealPlanRegion: true,
        mealPlanHasBeenRedirected: false,
      },
      () => mealPlanIntakeRouteByRegion
    )
    .otherwise(() => undefined);

  const shefLogoDisabled = match({ isInIntakeFlow, entryPoint })
    .with({ isInIntakeFlow: true, entryPoint: MealPlanEntryPoints.MPLandingPage }, () => true)
    .otherwise(() => false);

  /**
   * Determine if the user should see the persistent meal plan banner on Explore
   */
  const shouldShowMealPlanBanner: boolean = match({
    visitedMealPlans,
    isInMealPlanRegion,
  })
    .with({ isInMealPlanRegion: false }, () => false)
    .otherwise(() => true);

  /**
   * Determine if the user should see the meal plan modal on Explore
   */
  const shouldShowMealPlanModal: boolean = match({
    userIsLoggedIn,
    visitedMealPlans,
    hasSeenMealPlanModalLifetime,
    hasSeenMealPlanModalSession,
    isInMealPlanRegion,
    userHasActiveMealPlan,
  })
    .with({ isInMealPlanRegion: false }, () => false)
    .with({ userHasActiveMealPlan: true }, () => false)
    .with({ userIsLoggedIn: true, visitedMealPlans: true, hasSeenMealPlanModalLifetime: true }, () => false)
    .with({ userIsLoggedIn: true, visitedMealPlans: false, hasSeenMealPlanModalSession: true }, () => false)
    .with({ userIsLoggedIn: false, visitedMealPlans: true, hasSeenMealPlanModalLifetime: true }, () => false)
    .with({ userIsLoggedIn: false, visitedMealPlans: false, hasSeenMealPlanModalSession: true }, () => false)
    .otherwise(() => true);

  /**
   * Determine whether the Meal Plan button should be shown in the hamburger menu
   */
  const shouldShowMealPlanButton = match({
    userHasActiveMealPlan,
    visitedMealPlans,
    regionId,
    isInMealPlanRegion,
  })
    .with({ userHasActiveMealPlan: true }, () => true)
    .with(
      P.when((val) => !val.isInMealPlanRegion && val.regionId !== undefined),
      () => false
    )
    .otherwise(() => true);

  return {
    mealPlanUrl,
    mealPlanIntakeRouteByRegion,
    shefLogoDisabled,
    shouldShowMealPlanBanner,
    shouldShowMealPlanButton,
    shouldShowMealPlanModal,
    mealPlanHasBeenRedirected,
    setHasSeenMealPlanModal,
    setMealPlanHasBeenRedirected,
  };
};
