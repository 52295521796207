import cn from 'classnames';
import { CartChangeLocation } from 'common/events/ClientEvents';
import { getBasePriceForSideOrStaple } from 'common/MealPlanUtils';
import { MealPlanFormat } from 'src/gqlReactTypings.generated.d';
import { ShefImage } from 'src/pages/consumer/components/ShefImage';
import { MealPlanQuantityPicker } from 'src/pages/consumer/meal-plans/checkout/MealPlanQuantityPicker';
import { generateThumborImgUrl } from 'src/shared/design-system/DynamicImage/utils';
import { ThumbsUpV2 } from 'src/shared/design-system/Icon';
import { useCartStoreSelectors } from 'src/ztore/cart-store';
import { FoodItemRatings } from '../components/FoodItemRatings';
import { ShefHandwrittenName } from '../components/ShefHandwrittenName';
import { FoodItemChip } from './FoodItemChip';
import { PriceChip, ServingChip } from './MealPlanDishCard';
import { MealPlanFoodItem, MealPlanShefSegment } from './types';

interface IProps {
  buttonsDisabled: boolean;
  foodItem: MealPlanFoodItem;
  foodItemIndex: number;
  renderFullWidth: boolean;
  remainingAvailableUnits: number;
  servingsPerMeal: number;
  shefIndex: number;
  shefSegment: MealPlanShefSegment;
  showShef?: boolean;
  openDishModal: () => void;
  format: MealPlanFormat;
  cartChangeLocation: CartChangeLocation;
}

export const MealPlanDishCardSmall: React.FC<IProps> = ({
  buttonsDisabled,
  foodItem,
  foodItemIndex,
  renderFullWidth,
  remainingAvailableUnits,
  servingsPerMeal,
  shefIndex,
  shefSegment,
  format,
  showShef = false,
  openDishModal,
  cartChangeLocation,
}) => {
  const { cart } = useCartStoreSelectors();
  const { shef } = foodItem;

  const unitsInCart = cart[shef.id]?.cartItems.find((item) => item.foodItem.id === foodItem.id)?.quantity ?? 0;

  const foodItemChip = (
    <div className='absolute left-2 top-2'>
      <FoodItemChip remainingAvailableUnits={remainingAvailableUnits} unitsInCart={unitsInCart} />
    </div>
  );

  const imageSrc = generateThumborImgUrl({ src: foodItem.imageUrl ?? '', width: 149, height: 154 });

  const dishImage = (
    <div
      className='flex min-h-[149px] min-w-[154px] items-end bg-cover bg-center p-2'
      style={{ backgroundImage: `url(${imageSrc})` }}>
      <>{foodItemChip}</>
    </div>
  );

  const shefSection = (
    <section className='flex items-center gap-1.5'>
      <ShefImage height={32} width={32} smallScreenWidth={32} src={shefSegment.shef.imageUrl ?? ''} />
      <ShefHandwrittenName name={shef.publicName} className='text-label-600' />
    </section>
  );

  return (
    <div
      onClick={openDishModal}
      className={cn(
        'mb-4 box-border flex min-w-[340px] cursor-pointer gap-4',
        'overflow-hidden rounded-xl border border-neutral-100 bg-white pr-2 shadow-lg',
        { 'max-w-[min(358px,calc(100vw-32px))]': !renderFullWidth, 'w-full': renderFullWidth }
      )}>
      <div className='relative'>{dishImage}</div>

      <div className='flex w-full flex-col justify-between self-stretch py-4 pb-3'>
        <section>
          <p className='mb-2 line-clamp-2 text-h6-bold text-eggplant'>{foodItem.name}</p>
          <div className='flex items-center gap-0.5'>
            <ThumbsUpV2 outline height={12} width={12} />
            <FoodItemRatings
              ratingCount={foodItem.ratingCount}
              averageRating={foodItem.averageRating}
              foodItemId={foodItem.id}
            />
          </div>
        </section>
        {showShef && shefSection}
        <section>
          <hr className='mb-1.5 mt-1' />
          <div className='flex w-full flex-row justify-between pr-1'>
            <div className='flex gap-1'>
              <ServingChip numServings={servingsPerMeal} />
              <PriceChip
                price={
                  getBasePriceForSideOrStaple(foodItem.id, format === MealPlanFormat.FlexibleSides) +
                  (foodItem.upsell?.price ?? 0)
                }
              />
            </div>
            <MealPlanQuantityPicker
              foodItem={foodItem}
              value={unitsInCart}
              disabled={buttonsDisabled}
              shefSegment={shefSegment}
              shefPosition={shefIndex + 1}
              foodItemPosition={foodItemIndex + 1}
              cartChangeLocation={cartChangeLocation}
            />
          </div>
        </section>
      </div>
    </div>
  );
};
