// prettier-ignore
/* eslint max-len: "off" -- Ignoring line length and whitespace here lets us get a really nice grid in this one case */

// Shef Design Library Colors - see https://www.figma.com/file/F5qDke0RSLZUyDCy98kghu/Shef-Design-Library?node-id=1452%3A6013
// NOTE: The main color palette is arranged as a grid as of 4/2022, but can always be re-arranged in the future
/**
 * @deprecated Please use tailwindcss for all colors
 */
export enum Colors {
  // Grayscale
  BLACK        = '#000000',
  GRAY_DARK_30 = '#383838',
  GRAY_DARK_25 = '#4E4E4E',
  GRAY_DARK_20 = '#5C5C5C',
  GRAY_DARK_15 = '#767676', // One-off adjustment for typography
  GRAY_DARK_10 = '#959595',
  GRAY         = '#B8B8B8',
  GRAY_LITE_05 = '#CACACA', // One-off adjustment for borders
  GRAY_LITE_10 = '#D8D8D8',
  GRAY_LITE_15 = '#E4E4E4',// One-off adjustment for borders
  GRAY_LITE_20 = '#EBEBEC',
  GRAY_LITE_30 = '#F7F7F7',
  WHITE        = '#FFFFFF',
  NEUTRAL_50   = '#F5F5F5',
  
  // Full Color Palette
  PLUM_DARK_30   = '#320615',  BELL_PEPPER_DARK_30 = '#4A0007',  PUMPKIN_DARK_30    = '#430E02',  JACKFRUIT_DARK_30 = '#493308',
  PLUM_DARK_20   = '#591229',  BELL_PEPPER_DARK_20 = '#6E040F',  PUMPKIN_DARK_20    = '#822410',  JACKFRUIT_DARK_20 = '#996E00',
  PLUM_DARK_10   = '#922A4C',  BELL_PEPPER_DARK_10 = '#A40F1E',  PUMPKIN_DARK_10    = '#C84F2D',  JACKFRUIT_DARK_10 = '#E0A715',
  PLUM           = '#BC4C71',  BELL_PEPPER         = '#D93A4A',  PUMPKIN            = '#E3603C',  JACKFRUIT         = '#F7C647',
  PLUM_LITE_10   = '#F08FAF',  BELL_PEPPER_LITE_10 = '#FA6B7A',  PUMPKIN_LITE_10    = '#FA8C6E',  JACKFRUIT_LITE_10 = '#FFE195',
  PLUM_LITE_20   = '#FFD0E0',  BELL_PEPPER_LITE_20 = '#FFB4BB',  PUMPKIN_LITE_20    = '#FFCFC2',  JACKFRUIT_LITE_20 = '#FFF4D7',
  PLUM_LITE_30   = '#FFECF2',  BELL_PEPPER_LITE_30 = '#FFEAEC',  PUMPKIN_LITE_30    = '#FFEAE4',  JACKFRUIT_LITE_30 = '#FFFAEC',

  CHIVES_DARK_20 = '#054808',  KALE_DARK_30        = '#153C32',  ELDERBERRY_DARK_30 = '#072A48',  AQUA_DARK_30      = '#320615',
  CHIVES_DARK_10 = '#0B6510',  KALE_DARK_20        = '#246454',  ELDERBERRY_DARK_20 = '#133A5B',  AQUA_DARK_20      = '#003483',
  CHIVES         = '#1F8925',  KALE_DARK_10        = '#1B866A',  ELDERBERRY_DARK_10 = '#345C7E',  AQUA_DARK_10      = '#004FC6',
  CHIVES_LITE_10 = '#48BB4F',  KALE                = '#27B08D',  ELDERBERRY         = '#4379A7',  AQUA              = '#3174D8',
  CHIVES_LITE_20 = '#9AE19E',  KALE_LITE_10        = '#67C9B0',  ELDERBERRY_LITE_10 = '#92B9DA',  AQUA_LITE_10      = '#8BB3EF',
  CHIVES_LITE_30 = '#C9FCCC',  KALE_LITE_20        = '#BAF9E9',  ELDERBERRY_LITE_20 = '#D7ECFF',  AQUA_LITE_20      = '#C9DEFF',
  CHIVES_LITE_40 = '#E8FFE9',  KALE_LITE_30        = '#E4FFF8',  ELDERBERRY_LITE_30 = '#EDF7FF',  AQUA_LITE_30      = '#E8F1FF',

  // NOTE: The Chives color palette is one notch 'out of aligned'

  TRANSPARENT = 'rgba(255, 255, 255, 0)',

  // Other Color Palette
  KOHLRABI_DARK_40 = '#012B23', // Courgette 400
  KOHLRABI_DARK_30 = '#003D32', // Courgette 300
  KOHLRABI_DARK_20 = '#015F4E', // Courgette 200
  KOHLRABI_DARK_10 = '#0C8973', // Courgette 100
  KOHLRABI         = '#00AF90', // 300
  KOHLRABI_LITE_10 = '#83DCC3', // 200
  KOHLRABI_LITE_20 = '#ACEEDB', // 100
  KOHLRABI_LITE_30 = '#E5F7F4', // 20
  KOHLRABI_LITE_40 = '#F2FDFB', // 10

  RADISH_DARK_20   = '#821125', // 300
  RADISH_DARK_10   = '#BB1E3A', // 200
  RADISH           = '#EC2044', // 100
  RADISH_LITE_10   = '#FDE9EC', // 20
  RADISH_LITE_20   = '#FEF6F8', // 10

  // One-off colors
  ADMIN_BRIGHT_WARNING = '#FF0016',
  BEET = '#9E1C4C',
  CURRENT = 'currentColor',
  KALE_400 = '#89CFBB',

  // Koto Rebrand
  RASPBERRY = '#F01D59',
  RASPBERRY_HOVER = '#BE0036',
  RASPBERRY_ACTIVE = '#9B0024',

  RICE = '#FFF9F2',
  MILK = '#FFFFFF',
  SOURDOUGH = '#FFDBC7',
  TURMERIC = '#FEC27B',
  EGGPLANT = '#35081A',

  EGGPLANT_100 = '#F0D8D8',

  STRAWBERRY_50 = '#FFEFEF',
  STRAWBERRY_100 = '#FCE3E7',
  STRAWBERRY_200 = '#FFD0D0',
  STRAWBERRY_300 = '#FFB1B1',
  STRAWBERRY_400 = '#F49999',
  STRAWBERRY_500 = '#E47777',
  STRAWBERRY_600 = '#BA5858',

  CANTELOUPE_100 = '#FFEFE6',
  CANTELOUPE_200 = '#FFDFCD',
  CANTELOUPE_300 = '#FFCFB5',
  CANTELOUPE_400 = '#FFAF83',
  CANTELOUPE_500 = '#DB906B',
  CANTELOUPE_600 = '#CC7048',
  CANTELOUPE_700 = '#AF4A27',

  MINT_50 = '#F3FAE8',
  MINT_100 = '#E7F5D0',
  MINT_200 = '#DAEFB9',
  MINT_300 = '#C2E58A',
  MINT_400 = '#92AC68',
  MINT_500 = '#729136',
  MINT_600 = '#627A26',

  BUTTER_50 = '#FFF7E9',
  BUTTER_100 = '#FFEFD3',
  BUTTER_200 = '#FFE6BD',
  BUTTER_300 = '#FFD691',
  BUTTER_400 = '#F4BF73',
  BUTTER_500 = '#DDA254',
  BUTTER_600 = '#C6873C',
}
