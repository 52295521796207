import { MealPlanPromoCodeAppliedFragment, PromoCodeFieldsFragment } from 'src/gqlReactTypings.generated.d';
import { usePromotionalsHook } from 'src/promotionals/usePromotionalsHook';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { match, P } from 'ts-pattern';

/**
 *
 * @property {string} discountText - The text representation of the discount to be applied, i.e. '20%' or '$20'.
 * @property {string} totaledDiscountText - The total discount text considering the order count.
 * @property {boolean} isPercentageDiscount - Indicates if the discount is a percentage or a fixed amount.
 * @property {number} discount - The amount of discount to be applied.
 * @property {PromoCodeFieldsFragment | null} referralPromo - The referral promo code details or null if not applicable.
 * @property {string} orderCountText - The text indicating the order count for which the discount is applicable.
 * @property {number} orderCount - The number of orders for which the discount is applicable.
 * @property {string} numOrdersText - The text indicating the number of orders.
 * @property {string} referralPromoText - The text for the referral promo discount.
 * @property {string} promoText - The general promo text.
 * @property {(priceInCents: number) => number} applyDiscountToPrice - A function to apply the discount to a given
 *   price in cents.
 */

type MealPlanDiscount = {
  discountText: string;
  totaledDiscountText: string;
  isPercentageDiscount: boolean;
  discount: number;
  referralPromo: PromoCodeFieldsFragment | null;
  numOrdersText: string;
  promoText: string;
  promoEndText: string;
  isMealPlanDiscountEligible: boolean;
};

export const useMealPlanDiscount = (promoCodeApplied?: MealPlanPromoCodeAppliedFragment | null): MealPlanDiscount => {
  const { availablePromos, code } = usePromotionalsHook();
  const [currentUser] = useCurrentUser();

  const isMealPlanDiscountEligible =
    Boolean(promoCodeApplied) || (!currentUser?.hasActiveMealPlan && (currentUser?.isMealPlanDiscountEligible ?? true));
  const referralPromo = [...availablePromos, code].find((promo) => promo?.promoCodeType === 'REFERRAL') ?? null;

  const shouldUseReferralPromo =
    referralPromo != null && referralPromo?.maxUsesPerUser === referralPromo?.usesRemainingForUser;

  const discount =
    promoCodeApplied?.value ??
    (shouldUseReferralPromo ? referralPromo?.value : null) ??
    (isMealPlanDiscountEligible ? 30 : 0);

  const isPercentageDiscount =
    promoCodeApplied?.isPercentage ??
    (shouldUseReferralPromo ? referralPromo?.isPercentage : null) ??
    isMealPlanDiscountEligible;

  const discountText = match({ isPercentageDiscount, discount })
    .with({ isPercentageDiscount: true, discount: P.not(0) }, () => `${discount}%`)
    .with({ isPercentageDiscount: false, discount: P.not(0) }, () => `$${Math.floor(discount / 100)}`)
    .otherwise(() => '');

  const orderCount = shouldUseReferralPromo ? 2 : 1;
  const totaledDiscountText = match({ isPercentageDiscount, discount })
    .with({ isPercentageDiscount: true, discount: P.not(0) }, () => `${discount}%`)
    .with({ isPercentageDiscount: false, discount: P.not(0) }, () => `$${Math.floor((discount * orderCount) / 100)}`)
    .otherwise(() => '');
  const orderCountText = shouldUseReferralPromo ? 'first two' : 'first';
  const deliveryPluralityText = shouldUseReferralPromo ? 'deliveries' : 'delivery';
  const weekPluratiltyText = shouldUseReferralPromo ? 'weeks' : 'week';
  const numOrdersText = `${orderCountText} weekly ${deliveryPluralityText}`;
  const promoText = `Get ${discountText} off your ${numOrdersText}!`;
  const promoEndText = `off your ${orderCountText} ${weekPluratiltyText}`;

  return {
    discountText,
    totaledDiscountText,
    isPercentageDiscount,
    discount,
    referralPromo,
    numOrdersText,
    promoText,

    // Used for Explore Meal Plan Banner
    promoEndText,
    isMealPlanDiscountEligible,
  };
};
