interface ShefNameProps {
  name: string;
  className?: string;
}

export const ShefHandwrittenName: React.FC<ShefNameProps> = ({ name, className }) => {
  const handwrittenName = <span className='pl-1 font-quick-writers text-2xl text-raspberry'>{name}</span>;

  return <span className={className}>Shef {handwrittenName}</span>;
};
